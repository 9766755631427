import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/system/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import Status from "../../../common/enums/status";
import DeliveryItemCard from "./DeliveryItemCard";
import { LoadingButton } from "@mui/lab";
import InfoIcon from "@mui/icons-material/Info";
import HistoryModal from "./HistoryModal";
import { DeliveryRequestList } from "../../../domain/models/stakeholder/delivery-request-list";
import SuccessModal from "../modal/SuccessModal";
import ErrorModal from "../modal/ErrorModal";
import ConfirmModal from "../modal/ConfirmModal";
import {
  QPSMessage,
  QPSMessageColor,
  QPSMessageIcon,
} from "../../../common/utils";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import { SelectedRequestedItem } from "../../../domain/models/stakeholder/selected-request-item";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY } from "../../../base";
import AppCodeType from "../../../common/enums/appCodeType";
import OTPModes from "../../../common/enums/OTPModes";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
  pt: 1,
};

type Props = {
  openModal: boolean;
  handleClose: Function;
  deliveryRequest: DeliveryRequestList;
  rowIndex: number;
};

const QPSDetailsModal: React.FC<Props> = ({
  openModal,
  handleClose,
  rowIndex,
  deliveryRequest,
}) => {
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [OTPMode, setOTPMode] = useState<String>(OTPModes.SMS);

  const {
    deliveryRequestList,
    inputItems,
    onSubmit,
    successMessage,
    errorMessage,
    loading,
    fetchDeliveryRequestHistory,
  } = useDeliveryRequestStore();

  const handleOpenSuccessModal = () => setOpenSuccessModal(true);
  const handleCloseSuccessModal = () => setOpenSuccessModal(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);
  const handleOpenConfirmModal = () => setOpenConfirmModal(true);
  const handleCloseConfirmModal = () => setOpenConfirmModal(false);
  const handleChangeOTPMode = (value: string) => {
    setOTPMode(value);
  };

  const handleOpenHistoryModal = (index: number) => {
    fetchDeliveryRequestHistory(index);
    setOpenHistoryModal(true);
  };
  const handleCloseHistoryModal = () => setOpenHistoryModal(false);

  const hasNonZeroQuantity = (inputData: SelectedRequestedItem[][]) => {
    for (let i = 0; i < inputData[rowIndex].length; i++) {
      if (inputItems[rowIndex][i].quantity !== 0) {
        return true;
      }
    }

    return false;
  };
  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));
  return (
    <Stack>
      <Modal open={openModal} onClose={() => handleClose()}>
        <Box sx={style}>
          <Box textAlign="end">
            <IconButton onClick={() => handleClose()}>
              <CloseIcon
                color="error"
                fontSize="small"
                onClick={() => handleClose()}
              />
            </IconButton>
          </Box>

          <Typography variant="subtitle2" fontWeight={550}>
            View QPS Details
          </Typography>
          <Stack direction={"row"} spacing={1}>
            {deliveryRequest.reward_status != Status.PENDING &&
              deliveryRequest.reward_status != Status.PARTIAL && (
                <InfoIcon
                  color={QPSMessageIcon(
                    deliveryRequestList[rowIndex].reward_status
                  )}
                />
              )}

            <Typography
              fontSize={12}
              color={QPSMessageColor(
                deliveryRequestList[rowIndex].reward_status
              )}
            >
              {QPSMessage(deliveryRequestList[rowIndex].reward_status)}
            </Typography>
          </Stack>

          <Typography marginTop={2} marginBottom={1} color="info.dark">
            Input Deliverables
          </Typography>
          <Stack spacing={2}>
            {deliveryRequest.delivery_request_items.length > 0 &&
              deliveryRequest.delivery_request_items.map(
                (item, index: number) => {
                  return (
                    <DeliveryItemCard
                      item={item}
                      columnIndex={index}
                      rowIndex={rowIndex}
                      showAddItems={true}
                    />
                  );
                }
              )}
          </Stack>
          <Stack
            direction={"row"}
            spacing={2}
            marginTop={2}
            justifyContent="center"
          >
            {(deliveryRequest.reward_status == Status.PENDING ||
              deliveryRequest.reward_status == Status.PARTIAL) && (
              <LoadingButton
                loading={loading}
                disabled={
                  inputItems.length == 0 ||
                  (inputItems[rowIndex] && inputItems[rowIndex].length == 0) ||
                  (inputItems[rowIndex] &&
                    inputItems[rowIndex][0].quantity == 0)
                }
                color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
                loadingPosition="start"
                variant="contained"
                onClick={handleOpenConfirmModal}
                sx={{ borderRadius: 20, paddingInline: 5 }}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  textTransform="none"
                  color="white"
                >
                  Handover
                </Typography>
              </LoadingButton>
            )}
            {deliveryRequest.reward_status != Status.PENDING && (
              <Button
                color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
                onClick={() => handleOpenHistoryModal(rowIndex)}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight={700}
                  textTransform="none"
                >
                  View History
                </Typography>
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
      <SuccessModal
        openModal={openSuccessModal}
        handleClose={handleCloseSuccessModal}
        message={successMessage}
      />
      <ErrorModal
        openModal={openErrorModal}
        handleClose={handleCloseErrorModal}
        message={errorMessage}
      />
      <ConfirmModal
        openModal={openConfirmModal}
        handleClose={handleCloseConfirmModal}
        showOTPMode={true}
        OTPMode={OTPMode}
        handleChangeOTPMode={handleChangeOTPMode}
        message={
          <>
            <Typography fontWeight={500} textAlign="center">
              Are you sure you want to raise request for below mentioned items?
            </Typography>
            <Stack
              direction={"row"}
              justifyContent="center"
              spacing={2}
              fontWeight={600}
              color="info"
            >
              <Stack>
                {deliveryRequest.delivery_request_items.map((item) => {
                  return (
                    <Typography
                      variant="caption"
                      fontWeight={550}
                      color={"grey"}
                    >
                      {item.campaign_input.name}
                    </Typography>
                  );
                })}
              </Stack>
              <Stack>
                {inputItems[rowIndex]?.map((item) => {
                  return (
                    <Typography
                      variant="caption"
                      fontWeight={550}
                      color={"grey"}
                    >
                      x{item.quantity}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          </>
        }
        handleConfirm={() =>
          onSubmit(
            handleCloseConfirmModal,
            handleOpenErrorModal,
            handleOpenSuccessModal,
            rowIndex,
            OTPMode
          )
        }
      />
      <HistoryModal
        openModal={openHistoryModal}
        handleClose={handleCloseHistoryModal}
      />
    </Stack>
  );
};

export default QPSDetailsModal;
