import { Result } from "../../../domain/models/result";
import { FetchAcknowledgementList } from "../../../domain/usages/beneficiary/fetch-acknowledgement-list";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";

export class RemoteFetchAcknowledgement implements FetchAcknowledgementList {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(
    uuid: string,
    params?: FetchAcknowledgementList.Params
  ): Promise<Result> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url.replace(":uuid", uuid),
      query: params,
      headers: {
        ["ngrok-skip-browser-warning"]: "1",
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    return httpResponse.data;
  }
}
