import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
  LATITUDE_KEY,
  LONGITUDE_KEY,
} from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { Result } from "../../../domain/models/result";
import Endpoints from "../../../domain/endpoints";
import { useDeliveryRequestStore } from "../../main/deliveryRequestStore";
import { RemoteVerifyCustomerOTP } from "../../../data/usecases/stakeholder/remote-verify-customer-otp";
import { VerifyCustomerOTP } from "../../../domain/usages/stakeholder/verify-customer-otp";

interface VerifyCustomerOTPSliceInterface {
  onVerifyOTP: Function;
  successOTPMessage: string;
  errorOTPMessage: string;
  verifyOTPloading: boolean;
}

const initialState = {
  successOTPMessage: "",
  errorOTPMessage: "",
  verifyOTPloading: false,
};

export const useVerifyCustomerOTPSlice: StateCreator<
  VerifyCustomerOTPSliceInterface
> = (set) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    onVerifyOTP: async (
      handleOpenErrorModal: Function,
      handleOpenSuccessModal: Function,
      captchaToken: string,
      otp: string,
      index: number
    ) => {
      const token = storage.get(AUTH_TOKEN_KEY);
      const appCode = storage.get(APP_CODE_KEY);
      const latitudeValue = storage.get(LATITUDE_KEY);
      const longitudeValue = storage.get(LONGITUDE_KEY);
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: atob(token),
        [APP_CODE]: atob(appCode),
      });
      const verifyCustomerOTP: VerifyCustomerOTP = new RemoteVerifyCustomerOTP(
        `${INPUT_SERVICE_API_URL}${Endpoints.VERIFY_OTP}`,
        axiosHttpClient
      );
      let payload = {
        delivery_request_id: useDeliveryRequestStore
          .getState()
          .deliveryRequestList[index].id.toString(),
        otp: otp,
        captcha: captchaToken,
        latitude: latitudeValue,
        longitude: longitudeValue,
      };

      set(() => ({
        verifyOTPloading: true,
      }));
      let result: Result = await verifyCustomerOTP.verify(payload);
      if (result.success) {
        set(() => ({
          verifyOTPloading: false,
        }));
        set(() => ({
          successOTPMessage: result.otpVerify,
        }));
        handleOpenSuccessModal();
        setTimeout(
          () =>
            useDeliveryRequestStore
              .getState()
              .fetchDeliveryRequestList("", 1, false),
          3000
        );
      } else if (result.errors.message) {
        set(() => ({
          verifyOTPloading: false,
        }));
        set(() => ({
          errorOTPMessage: result.errors.message,
        }));
        handleOpenErrorModal();
      } else {
        set(() => ({
          verifyOTPloading: false,
        }));
        set(() => ({
          errorOTPMessage: result.errors.message,
        }));
        handleOpenErrorModal();
      }
    },
  };
};
