import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

type Props = {
  title: string;
  titleValue: string;
  width?: number;
  cardColor?: string;
  textColor?: string;
};

const SummaryCard: React.FC<Props> = ({
  title,
  titleValue,
  width = 150,
  cardColor = "white",
  textColor = "black",
}) => {
  return (
    <Card sx={{ bgcolor: cardColor, width: width, borderRadius: 2 }}>
      <CardContent>
        <Stack alignItems={"center"}>
          <Typography color={textColor} variant="subtitle2">
            {title}
          </Typography>
          <Typography color={textColor} variant="h5">
            {titleValue}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
