import { StateCreator } from "zustand";
import { AUTH_HEADER, CHANNELPAISA_API_URL } from "../../../base";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { FetchState } from "../../../common/enums/fetch-state";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchClients } from "../../../data/usecases/stakeholder/remote-fetch-clients";
import { FetchClients } from "../../../domain/usages/stakeholder/fetch-clients";
import { Client } from "../../../domain/models/client";
import { Constants } from "../../../common/constant";

interface FetchClientsInterface {
  fetchClientsList: Function;
  clientsList: Client[];
}

const initialState = {
  fetchClientState: FetchState.DEFAULT,
  clientsList: [],
};

export const useFetchClientsSlice: StateCreator<FetchClientsInterface> = (
  set
) => {
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    fetchClientsList: async () => {
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: Constants.CHANNELPAISA_TOKEN,
      });
      const fetchClients: FetchClients = new RemoteFetchClients(
        `${CHANNELPAISA_API_URL}${Endpoints.FETCH_CLIENTS}`,
        axiosHttpClient
      );

      let result = await fetchClients.fetch();
      if (result.success) {
        set(() => ({
          clientsList: result.clients.data,
        }));
      }
    },
  };
};
