import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const RequestListSkeleton = () => {
  return (
    <Stack>
      <Box border={"solid 2px #ddd"} padding={2} borderRadius={4} marginBottom={2}>
        <Stack direction="row" justifyContent="space-between" marginY={2}>
          <Skeleton animation="wave" variant="text" width={70} height={40} />
          <Skeleton animation="wave" variant="text" width={120} height={40} />
        </Stack>
        <Skeleton animation="wave" variant="rectangular" width={90} height={25} />
        <Skeleton animation="wave" variant="text" width={150} height={30} />
        <Skeleton animation="wave" variant="text" width={170} height={20} />
        <Skeleton animation="wave" variant="text" width={130} height={20} />
      </Box>
    </Stack>
  );
};

export default RequestListSkeleton;
