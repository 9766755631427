import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
} from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { Result } from "../../../domain/models/result";
import Endpoints from "../../../domain/endpoints";
import { useDeliveryRequestStore } from "../../main/deliveryRequestStore";
import { DeliveryRequestHistoryList } from "../../../domain/models/stakeholder/delivery-request-history-list";
import { FetchState } from "../../../common/enums/fetch-state";
import { RemoteFetchDeliveryRequestHistory } from "../../../data/usecases/stakeholder/remote-fetch-delivery-request-history";
import { FetchDeliveryRequestHistory } from "../../../domain/usages/stakeholder/fetch-delivery-request-history";

interface DeliveryRequestHistoryInterface {
  deliveryRequestHistoryList: DeliveryRequestHistoryList[];
  fetchDeliveryRequestHistory: Function;
  historyFetchState: FetchState;
}

const initialState = {
  deliveryRequestHistoryList: [],
  historyFetchState: FetchState.DEFAULT,
};

export const useDeliveryRequestHistorySlice: StateCreator<
  DeliveryRequestHistoryInterface
> = (set) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    fetchDeliveryRequestHistory: async (index: number) => {
      const token = storage.get(AUTH_TOKEN_KEY);
      const appCode = storage.get(APP_CODE_KEY);
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: atob(token),
        [APP_CODE]: atob(appCode),
      });
      const remoteFetchDeliveryRequestHistory: FetchDeliveryRequestHistory =
        new RemoteFetchDeliveryRequestHistory(
          `${INPUT_SERVICE_API_URL}${Endpoints.DELIVERY_REQUEST_HISTORY}`,
          axiosHttpClient
        );

      let payload = {
        delivery_request_id:
          useDeliveryRequestStore.getState().deliveryRequestList[index].id,
      };
      set(() => ({
        historyFetchState: FetchState.LOADING,
      }));
      let result: Result = await remoteFetchDeliveryRequestHistory.fetch(
        payload
      );
      if (result.success) {
        set(() => ({
          deliveryRequestHistoryList: result.history,
        }));
        set(() => ({
          historyFetchState: FetchState.SUCCESS,
        }));
      }
    },
  };
};
