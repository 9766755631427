import { Constants } from "../../../common/constant";
import { EmptyResponse, Result } from "../../../domain/models/result";
import { VerifyCustomerOTP } from "../../../domain/usages/stakeholder/verify-customer-otp";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteVerifyCustomerOTP implements VerifyCustomerOTP {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async verify(params: VerifyCustomerOTP.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: true,
    });
    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: Constants.SERVER_ERROR };
      return response;
    } else if (httpResponse.status === 500) {
      let response = EmptyResponse;
      response.errors = { message: Constants.SERVER_ERROR };
      return response;
    } else {
      return httpResponse.body ? httpResponse.body : httpResponse.data;
    }
  }
}
