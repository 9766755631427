import { Component, ReactNode } from "react";
import ErrorPage from "./ErrorPage";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage clientSideError={true} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
