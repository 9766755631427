import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
} from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { FetchState } from "../../../common/enums/fetch-state";
import { DeliveryRequestList } from "../../../domain/models/stakeholder/delivery-request-list";
import { Result } from "../../../domain/models/result";
import Endpoints from "../../../domain/endpoints";
import { RemoteFetchDeliveryRequest } from "../../../data/usecases/stakeholder/remote-fetch-delivery-request";
import { DeliveryRequestFilter } from "../../../domain/models/stakeholder/delivery-request-filter";
import { FetchDeliveryRequest } from "../../../domain/usages/stakeholder/fetch-delivery-request";

interface FetchDeliveryRequestInterface {
  fetchState: FetchState;
  fetchDeliveryRequestList: Function;
  currentPage: number;
  lastPage: number;
  total: number;
  deliveryRequestList: DeliveryRequestList[];
  filter: DeliveryRequestFilter;
}

const initialState = {
  fetchState: FetchState.DEFAULT,
  currentPage: 1,
  lastPage: 1,
  total: 0,
  filter: {} as DeliveryRequestFilter,
  deliveryRequestList: [],
};

export const useFetchDeliveryRequestSlice: StateCreator<
  FetchDeliveryRequestInterface
> = (set, get) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    fetchDeliveryRequestList: async (
      searchValue: string,
      page: number,
      scrolled: boolean
    ) => {
      const token = storage.get(AUTH_TOKEN_KEY);
      const appCode = storage.get(APP_CODE_KEY);
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: atob(token),
        [APP_CODE]: atob(appCode),
      });
      const fetchDeliveryRequest: FetchDeliveryRequest =
        new RemoteFetchDeliveryRequest(
          `${INPUT_SERVICE_API_URL}${Endpoints.FETCH_DELIVERY_REQUEST}`,
          axiosHttpClient
        );
      const payload = {
        q: searchValue,
        page: page.toString(),
        status: get().filter.status,
        from_date: get().filter.from_date,
        to_date: get().filter.to_date,
      };
      if (!scrolled) {
        set(() => ({
          fetchState: FetchState.LOADING,
        }));
      }

      let result: Result = await fetchDeliveryRequest.fetch(payload);
      if (result.success) {
        set(() => ({
          total: result.total,
        }));
        set(() => ({
          currentPage: result.current_page,
        }));
        set(() => ({
          lastPage: result.last_page,
        }));
        if (scrolled) {
          set(() => ({
            deliveryRequestList: [
              ...get().deliveryRequestList,
              ...result.delivery_requests.data,
            ],
          }));
        } else {
          set(() => ({
            deliveryRequestList: result.delivery_requests.data,
          }));
        }
        set(() => ({
          fetchState: FetchState.SUCCESS,
        }));
      }
    },
  };
};
