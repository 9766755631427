export const AUTH_HEADER: string = "auth-token";
export const APP_CODE: string = "kid";
export const INPUT_SERVICE_API_URL =
  process.env.REACT_APP_INPUT_SERVICE_API_URL;
export const CHANNELPAISA_API_URL = process.env.REACT_APP_CHANNELPAISA_API_URL;
export const AUTH_TOKEN_KEY: any = process.env.REACT_APP_TOKEN_KEY;
export const APP_CODE_KEY: any = process.env.REACT_APP_APP_CODE_KEY;
export const LATITUDE_KEY: any = process.env.REACT_APP_LATITUDE_KEY;
export const LONGITUDE_KEY: any = process.env.REACT_APP_LONGITUDE_KEY;
export const UUID_KEY: any = process.env.REACT_APP_UUID_KEY;
export const CONFIRM_LOGIN_KEY: any = process.env.REACT_APP_CONFIRM_LOGIN_KEY;
export const GOOGLE_RECAPTCHE_SITE_KEY =
  process.env.REACT_APP_GOOGLE_RECAPTCHE_SITE_KEY!;
export const GOOGLE_RECAPTCHE_URL = process.env.REACT_APP_GOOGLE_RECAPTCHE_URL!;
