import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
  LATITUDE_KEY,
  LONGITUDE_KEY,
} from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { Result } from "../../../domain/models/result";
import Endpoints from "../../../domain/endpoints";
import { useDeliveryRequestStore } from "../../main/deliveryRequestStore";
import { RemoteSubmitAcknowledgement } from "../../../data/usecases/stakeholder/remote-submit-acknowledgement";
import { SubmitAcknowledgement } from "../../../domain/usages/stakeholder/submit-acknowledgement";

interface DeliveryRequestSubmitInterface {
  onSubmit: Function;
  successMessage: string;
  errorMessage: string;
  loading: boolean;
  handleLoading: Function;
}

const initialState = {
  successMessage: "",
  errorMessage: "",
  loading: false,
};

export const useDeliveryRequestSubmitSlice: StateCreator<
  DeliveryRequestSubmitInterface
> = (set) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    handleLoading: (value: boolean) => {
      set(() => ({
        loading: value,
      }));
    },
    onSubmit: async (
      handleCloseConfirmModal: Function,
      handleOpenErrorModal: Function,
      handleOpenSuccessModal: Function,
      index: number,
      OTPMode: string
    ) => {
      const token = storage.get(AUTH_TOKEN_KEY);
      const appCode = storage.get(APP_CODE_KEY);
      const latitudeValue = storage.get(LATITUDE_KEY);
      const longitudeValue = storage.get(LONGITUDE_KEY);
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: atob(token),
        [APP_CODE]: atob(appCode),
      });
      const submitAcknowledgement: SubmitAcknowledgement =
        new RemoteSubmitAcknowledgement(
          `${INPUT_SERVICE_API_URL}${Endpoints.SUBMIT_ACKNOWLEDGEMENT}`,
          axiosHttpClient
        );
      const filteredInputItems = useDeliveryRequestStore
        .getState()
        .inputItems[index].filter((item) => item.quantity != 0);

      if (filteredInputItems.length > 0) {
        let payload = {
          delivery_request_id:
            useDeliveryRequestStore.getState().deliveryRequestList[index].id,
          items: filteredInputItems,
          latitude: latitudeValue,
          longitude: longitudeValue,
          otp_mode: OTPMode
        };

        handleCloseConfirmModal();
        set(() => ({
          loading: true,
        }));
        let result: Result = await submitAcknowledgement.submit(payload);
        if (result.success) {
          set(() => ({
            loading: false,
          }));
          set(() => ({
            successMessage: result.message,
          }));
          handleOpenSuccessModal();
          setTimeout(
            () =>
              useDeliveryRequestStore
                .getState()
                .fetchDeliveryRequestList("", 1, false),
            3000
          );
        } else if (result.errors.message) {
          set(() => ({
            loading: false,
          }));
          set(() => ({
            errorMessage: result.errors.message,
          }));
          handleOpenErrorModal();
        } else {
          set(() => ({
            loading: false,
          }));
          set(() => ({
            errorMessage: result.message,
          }));
          handleOpenErrorModal();
        }
      } else {
        set(() => ({
          errorMessage: "Cannot submit zero quantity value",
        }));
        handleOpenErrorModal();
      }
    },
  };
};
