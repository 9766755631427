import { Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import IMAGES from "../../../../assets/images";
import { Constants } from "../../../../common/constant";
import { statusColor } from "../../../../common/utils";
import { AcknowledgementList } from "../../../../domain/models/beneficiary/acknowledgement-list";
import Tag from "../../Tag";

type Props = {
  data: AcknowledgementList;
};

const AcknowlegementCard: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Stack direction={"row"} justifyContent="space-between">
        <img src={IMAGES.Gorupai} width="10%" />
        <Typography variant="caption" fontWeight={600}>
          {data.created_date
            ? moment(data.created_date).format(Constants.DATE_FORMAT)
            : ""}
        </Typography>
      </Stack>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        marginTop={2}
      >
        <Stack direction={"row"} gap={1}>
          <Tag
            title={
              <Typography variant="caption" color="white">
                {data.campaign_name
                  ?.toLowerCase()
                  .replace(Constants.FIRST_LETTER_REGEX, (letter) =>
                    letter.toUpperCase()
                  )}
              </Typography>
            }
            color="info"
          />
          <Tag
            title={
              <Typography variant="caption" color="white">
                {data.campaign_organisation_code
                  ?.toLowerCase()
                  .replace(Constants.FIRST_LETTER_REGEX, (letter) =>
                    letter.toUpperCase()
                  )}
              </Typography>
            }
            color="info"
          />
        </Stack>

        <Typography
          variant="caption"
          fontWeight={600}
          color={statusColor(data.acknowledgement_status)}
        >
          {data.acknowledgement_status
            ?.toLowerCase()
            .replace(Constants.FIRST_LETTER_REGEX, (letter) =>
              letter.toUpperCase()
            )}
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        mt={1.5}
        mb={1}
      >
        <Stack width={"65%"}>
          <Typography variant="body2" fontWeight={600}>
            #{data.reward_reference_number}
            <Typography variant="caption" paddingLeft={0.5}>
              have received an acknowledgement
            </Typography>
          </Typography>
        </Stack>
        <Button color={"secondary"} variant="contained" size="small">
          <Typography
            variant="caption"
            textTransform="capitalize"
            onClick={() => (window.location.href = data.go_rupi_link)}
          >
            View
          </Typography>
        </Button>
      </Stack>
    </>
  );
};

export default AcknowlegementCard;
