import { Constants } from "../../../common/constant";
import { EmptyResponse, Result } from "../../../domain/models/result";
import { DeliveryRequestsBulkUpload } from "../../../domain/usages/stakeholder/delivery-requests-bulk-upload";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpPostClient } from "../../protocols/http/http-post-client";

export class RemoteDeliveryRequestsBulkUpload
  implements DeliveryRequestsBulkUpload
{
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpPostClient
  ) {}

  async upload(params: DeliveryRequestsBulkUpload.Params): Promise<Result> {
    const httpResponse = await this.httpPostClient.post({
      url: this.url,
      body: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: "multipart/form-data",
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: false,
    });
    if (!httpResponse) {
      let response = EmptyResponse;
      response.errors = { message: Constants.SERVER_ERROR };
      return response;
    }
    return httpResponse.body ? httpResponse.body : httpResponse.data;
  }
}
