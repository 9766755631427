import IMAGES from "../assets/images";
import { ReportFilters } from "../domain/models/admin/report-filters";
import AdminTabs from "./enums/adminTabs";
import Status from "./enums/status";

export const requestStatus = (status: string) => {
  if (status == Status.CANCELED) {
    return "Canceled";
  } else if (status == Status.PENDING) {
    return "Pending";
  } else if (status == Status.FULFILLED) {
    return "Fulfilled";
  } else if (status == Status.REJECTED) {
    return "Rejected";
  } else if (status == Status.ACKNOWLEDGEMENT_RAISED) {
    return "Acknowledgement Raised";
  }
};

export const BeneficiaryValidationMessage = (receiverName: string) => {
 
    return `Please enter OTP received on ${receiverName}'s mobile number to confirm QPS delivery`
  
}

export const statusIcon = (status: string) => {
  if (status == Status.CANCELED) {
    return IMAGES.RejectedIcon;
  } else if (status == Status.PENDING) {
    return IMAGES.PendingIcon;
  } else if (status == Status.FULFILLED) {
    return IMAGES.AcceptedIcon;
  } else if (status == Status.REJECTED) {
    return IMAGES.RejectedIcon;
  } else if (status == Status.ACKNOWLEDGEMENT_RAISED) {
    return IMAGES.WaitingIcon;
  } else if (status == Status.PARTIAL) {
    // else if (status == Status.PARTIALLY_FULFILLED) {
    return IMAGES.PartiallyAcknowledged;
  } else if (status == Status.PARTIAL) {
    return IMAGES.PartiallyAcknowledged;
  }
};

export const statusColor = (status: string) => {
  if (status == Status.CANCELED) {
    return "#DB595A";
  } else if (status == Status.PENDING) {
    return "#FFA627";
  } else if (status == Status.FULFILLED) {
    return "#0EA465";
  } else if (status == Status.REJECTED) {
    return "#DB595A";
  } else if (status == Status.ACKNOWLEDGEMENT_RAISED) {
    return "#DB595A";
  } 
   else if (status == Status.PARTIAL) {
    return "#666600";
  }
};

export const statusMessage = (status: string) => {
  if (status == Status.CANCELED) {
    return "Acknowledgement has been canceled";
  } else if (status == Status.PENDING) {
    return "Acknowledgement is pending";
  } else if (status == Status.FULFILLED) {
    return "Acknowledgement has been fullfilled";
  } else if (status == Status.REJECTED) {
    return "Acknowledgement has been rejected";
  } else if (status == Status.ACKNOWLEDGEMENT_RAISED) {
    return "Waiting for acknowledgement";
  } else if (status == Status.PARTIAL) {
    // else if (status == Status.PARTIALLY_FULFILLED) {
    return "Partially acknowledged";
  } else if (status == Status.PARTIAL) {
    return "Partially acknowledged";
  }
};

export const statusBackgroundColor = (status: string) => {
  if (status == "canceled") {
    return "#FDD8DF";
  } else if (status == Status.PENDING) {
    return "#FDE6C5";
  } else if (status == Status.FULFILLED) {
    return "#E3FFF3";
  } else if (status == Status.REJECTED) {
    return "#FDD8DF";
  } else if (status == Status.ACKNOWLEDGEMENT_RAISED) {
    return "#FEECEF";
  } 
   else if (status == Status.PARTIAL) {
    return "#FAFAD2";
  }
};

export const QPSMessage = (status: string) => {
  if (status === Status.PENDING) {
    return "Enter the quantity for the following deliverables you need to handover to customer";
  } else if (status === Status.PARTIAL) {
    return " Customer already acknowledged some quantity. Please raise acknowledgement for pending quantities.";
  }
};

export const QPSCardMessage = (status: string) => {
  if (status == Status.CANCELED) {
    return "Acknowlegment has been canceled";
  } else if (status == Status.PENDING) {
    return "Deliverables to be handed over";
  } else if (status == Status.FULFILLED) {
    return "Customer has successfully received all QPS items";
  } else if (status == Status.REJECTED) {
    return "Acknowlegment has been rejected";
  } else if (status == Status.ACKNOWLEDGEMENT_RAISED) {
    return `Acknowledgement confirmation from the customer is still pending. Please click on confirm delivery and enter OTP received on customer's mobile number to confirm QPS delivery.`;
  } else if (status == Status.PARTIAL) {
    return " Customer already acknowledged some quantity. Please raise acknowledgement for pending quantities.";
  }
};

export const QPSMessageColor = (status: string) => {
  if (status === Status.CANCELED) {
    return "#DB595A";
  } else if (status === Status.PENDING) {
    return "info.main";
  } else if (status === Status.FULFILLED) {
    return "success.main";
  } else if (status === Status.REJECTED) {
    return "#DB595A";
  } else if (status === Status.ACKNOWLEDGEMENT_RAISED) {
    return "error.main";
  } else if (status === Status.PARTIAL) {
    return "warning.main";
  }
};

export const QPSMessageIcon = (status: string) => {
  if (status == "canceled") {
    return "error";
  } else if (status == "pending") {
    return "info";
  } else if (status == "fulfilled") {
    return "success";
  } else if (status == "rejected") {
    return "error";
  } else if (status == "acknowledgement_raised") {
    return "error";
  } else if (status == Status.PARTIAL) {
    return "warning";
  }
};

export const showAppCodeField = (type: string) => {
  if (type === AdminTabs.DASHBOARD || type === AdminTabs.CAMPAIGN_CRITERIA) {
    return true;
  }
  return false;
};

export const showOrganizationFilter = (type: string) => {
  if (type === AdminTabs.DASHBOARD) {
    return true;
  }
  return false;
};
export const showDivisionFilter = (type: string) => {
  if (type === AdminTabs.DASHBOARD) {
    return true;
  }
  return false;
};
export const showStartMonthAndYearFilter = (type: string) => {
  if (type === AdminTabs.DASHBOARD) {
    return true;
  }
  return false;
};
export const showEndMonthAndYearFilter = (type: string) => {
  if (type === AdminTabs.DASHBOARD) {
    return true;
  }
  return false;
};

export const DisableSubmitButton = (filters: ReportFilters) => {
  if (
    filters.appCode != "" ||
    filters.organization != "" ||
    filters.division != "" ||
    (filters.startDate != null && filters.endDate != null)
  ) {
    return false;
  }
  return true;
};
