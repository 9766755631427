import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
  UUID_KEY,
} from "../../../base";
import { FetchState } from "../../../common/enums/fetch-state";
import { RemoteFetchAcknowledgement } from "../../../data/usecases/beneficiary/remote-fetch-acknowledgement";
import Endpoints from "../../../domain/endpoints";
import { AcknowledgementList } from "../../../domain/models/beneficiary/acknowledgement-list";
import { AcknowledgementFilters } from "../../../domain/models/beneficiary/acknowledgementFilter";
import { Result } from "../../../domain/models/result";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";

interface BeneficiaryInterface {
  currentPage: number;
  lastPage: number;
  total: number;
  fetchAcknowledgements: Function;
  filter: AcknowledgementFilters;
  fetchState: FetchState;
  showFilter: boolean;
  acknowledgementList: AcknowledgementList[];
  handleAppliedFilters: Function;
  handleShowFilter: Function;
}

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

const initialState = {
  currentPage: 1,
  lastPage: 1,
  total: 0,
  fetchState: FetchState.DEFAULT,
  filter: {} as AcknowledgementFilters,
  acknowledgementList: [],
  showFilter: false,
};

export const useAcknowledgementListSlice: StateCreator<BeneficiaryInterface> = (
  set,
  get
) => {

  {
    return {
      ...initialState,
      fetchAcknowledgements: async (
        page: number,
        scrolled: boolean,
        filter: AcknowledgementFilters
      ) => {
        const token = storage.get(AUTH_TOKEN_KEY);
        const appCode = storage.get(APP_CODE_KEY);
        const uuid: string = storage.get(UUID_KEY);

        axiosHttpClient.setAuthHeaders({
          [AUTH_HEADER]: atob(token),
          [APP_CODE]: atob(appCode),
        });
        const remoteFetchAcknowledgement = new RemoteFetchAcknowledgement(
          `${INPUT_SERVICE_API_URL}${Endpoints.FETCH_ACKNOWLEDGEMENT}`,
          axiosHttpClient
        );
        const payload = {
          page: page.toString(),
          status: filter.status,
          from_date: filter.from_date,
          to_date: filter.to_date,
        };
        if (!scrolled) {
          set(() => ({
            fetchState: FetchState.LOADING,
          }));
        }
        let result: Result = await remoteFetchAcknowledgement.fetch(
          uuid,
          payload
        );
        if (result.success) {
          set(() => ({
            total: result.total,
          }));
          set(() => ({
            currentPage: result.current_page,
          }));
          set(() => ({
            lastPage: result.last_page,
          }));
          if (scrolled) {
            set(() => ({
              acknowledgementList: [
                ...get().acknowledgementList,
                ...result.acknowledgements.data,
              ],
            }));
          } else {
            set(() => ({
              acknowledgementList: result.acknowledgements.data,
            }));
          }
          set(() => ({
            fetchState: FetchState.SUCCESS,
          }));
        }
      },
      handleAppliedFilters: () => {
        get().fetchAcknowledgements(1, false, get().filter);
      },
      handleShowFilter: (value: boolean) => {
        set(() => ({
          showFilter: value,
        }));
      },
    };
  }
};
