import { Chip, Typography } from "@mui/material";
import React from "react";

type myProps = {
  title: React.ReactNode;
  size?: "small" | "medium";
  color:
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
};
const Tag: React.FC<myProps> = ({ title, color, size = "small" }) => {
  return (
    <Chip
      variant="filled"
      color={color}
      label={title}
      size={size}
      sx={{ borderRadius: 2 }}
    />
  );
};

export default Tag;
