import { Stack } from "@mui/material";
import ViewAcknowledgement from "../components/beneficiary/acknowledgement/ViewAcknowledgement";

type Props = {};

const BeneficiaryScreen = (props: Props) => {
  return (
    <Stack>
      <ViewAcknowledgement />
    </Stack>
  );
};

export default BeneficiaryScreen;
