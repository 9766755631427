import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/system/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { Icon, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
};

type myProps = {
  openModal: boolean;
  handleClose: Function;
  message: string;
};

const SuccessModal: React.FC<myProps> = ({
  openModal,
  handleClose,
  message,
}) => {
  return (
    <Modal open={openModal} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems={"center"}
            pt={1}
            pr={1}
            justifyContent="end"
          >
            <IconButton onClick={() => handleClose()}>
              <CloseIcon color="error" fontSize="small" />
            </IconButton>
          </Stack>
          <Stack p={3} pt={0} spacing={2} alignItems="center">
            <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />

            <Typography variant="h6" fontWeight={500} textAlign="center">
              {message}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
