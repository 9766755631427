import { Stack } from "@mui/material";
import DeliveryRequestList from "../components/representative/DeliveryRequestList";

type Props = {};

const StakeholderScreen = (props: Props) => {
  return (
    <Stack>
      <DeliveryRequestList />
    </Stack>
  );
};

export default StakeholderScreen;
