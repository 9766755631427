import { create } from "zustand";
import { useSelectedTabValueSlice } from "../slices/admin/selectedTabValue";
import { useCampaignCriteriaListSlice } from "../slices/admin/campaignCriteriaListSlice";
import { useAppCodesSlice } from "../slices/admin/appCodesSlice";

interface AdminStoreInterface
  extends ReturnType<typeof useSelectedTabValueSlice>,
    ReturnType<typeof useCampaignCriteriaListSlice>,
    ReturnType<typeof useAppCodesSlice> {}

export const useAdminStore = create<AdminStoreInterface>((...data) => ({
  ...useSelectedTabValueSlice(...data),
  ...useCampaignCriteriaListSlice(...data),
  ...useAppCodesSlice(...data),
}));
