import { Avatar, Card, CardHeader, Typography } from "@mui/material";
import React from "react";
import { CriteriaDetails } from "../../../domain/models/admin/criteriaDetails";

type Props = {
  criteriaDetails: CriteriaDetails;
};

const CampaignDetailsCard: React.FC<Props> = ({ criteriaDetails }) => {
  return (
    <Card variant="outlined" sx={{ bgcolor: "#F4EFF1", borderRadius: 3 }}>
      <CardHeader
        title={
          <Typography variant="subtitle2">
            {criteriaDetails.description}
          </Typography>
        }
        subheader={
          <Typography variant="h6" fontWeight={550}>
            {criteriaDetails.min_business_value}
          </Typography>
        }
        avatar={
          <Avatar
            sx={{ width: 56, height: 56 }}
            src={criteriaDetails.image_url}
          />
        }
      />
    </Card>
  );
};

export default CampaignDetailsCard;
