import { Box, Button, Stack, Typography } from "@mui/material";
import QPSTabs from "./QPSTabs";
import LogoutIcon from "@mui/icons-material/Logout";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../routes";

type Props = {};

const Header = (props: Props) => {
  const jsonStorage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(jsonStorage);
  const navigate = useNavigate();

  const handleLogout = () => {
    loggedInUser.setLoggedIn(false);
    navigate(pageRoutes.login);
  };
  return (
    <Box padding={2} borderBottom={"2px solid lightgrey"}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6">Admin QPS Management</Typography>
        <Stack direction={"row"} spacing={5}>
          <QPSTabs />
          <Button onClick={handleLogout}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <LogoutIcon fontSize="small" color="warning" />
              <Typography textTransform={"none"} color={"warning.main"}>
                Logout
              </Typography>
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default Header;
