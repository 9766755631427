import { Stack, Typography } from "@mui/material";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "Jan",
    CPaisa: 30,
    CPay: 30,
  },
  {
    name: "Feb",
    CPaisa: 33,
    CPay: 80,
  },
  {
    name: "Mar",
    CPaisa: 70,
    CPay: 40,
  },
  {
    name: "Apr",
    CPaisa: 40,
    CPay: 70,
  },
  {
    name: "May",
    CPaisa: 40,
    CPay: 70,
  },
  {
    name: "June",
    CPaisa: 40,
    CPay: 70,
  },
  {
    name: "July",
    CPaisa: 40,
    CPay: 70,
  },
  {
    name: "Aug",
    CPaisa: 40,
    CPay: 70,
  },
  {
    name: "Sep",
    CPaisa: 70,
    CPay: 40,
  },
  {
    name: "Oct",
    CPaisa: 40,
    CPay: 70,
  },
  {
    name: "Nov",
    CPaisa: 70,
    CPay: 40,
  },
  {
    name: "Dec",
    CPaisa: 40,
    CPay: 70,
  },
];

type Props = {};

const AppWiseQPSReport = (props: Props) => {
  return (
    <Stack>
      <Typography marginLeft={1} variant="subtitle2">
        App-Wise QPS Report
      </Typography>
      <AreaChart
        width={700}
        height={200}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend align="right" verticalAlign="top" iconType="circle" />

        <Area
          type="monotone"
          dataKey="CPaisa"
          stroke="#F39600"
          fill="#F39600"
        />
        <Area type="monotone" dataKey="CPay" stroke="#37CFA2" fill="#37CFA2" />
      </AreaChart>
    </Stack>
  );
};

export default AppWiseQPSReport;
