const IMAGES = {
  Pharmacist: require("./pharmacist_otp.svg").default,
  NoGift: require("./nogift.svg").default,
  ChannelPayLogo: require("./channelPayLogo.svg").default,
  Error: require("./error.svg").default,
  Unauthorized: require("./unauthorized.svg").default,
  Gorupai: require("./gorupai.svg").default,
  PendingIcon: require("./pending.svg").default,
  AcceptedIcon: require("./accepted.svg").default,
  RejectedIcon: require("./rejected.svg").default,
  WaitingIcon: require("./waiting.svg").default,
  PartiallyAcknowledged: require("./partially_acknowledged.svg").default,
  LoginLogo: require("./login.svg").default,
  NoLocation: require("./location.svg").default,
};

export default IMAGES;
