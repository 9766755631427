import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import CustomIconButton from "./CustomIconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import InstructionCard from "../uploadFile/InstructionCard";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { pageRoutes } from "../../../routes";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { CONFIRM_LOGIN_KEY } from "../../../base";
import { useAdminStore } from "../../../store/main/adminStore";
import AdminTabs from "../../../common/enums/adminTabs";

type Props = {};

const UploadQPSFile = (props: Props) => {
  const {
    downloadTemplate,
    downloadLatestUploadedQPS,
    uploadDeliveryRequestFile,
    fetchClientsList,
  } = useDeliveryRequestStore();

  const { handleSelectedTab } = useAdminStore();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const jsonStorage = LocalJsonStorage.getInstance();
  const loginStatus = jsonStorage.get(CONFIRM_LOGIN_KEY);

  useEffect(() => {
    if (!loginStatus) {
      navigate(pageRoutes.login);
    }
    fetchClientsList();
  }, []);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      const uniqueId = Date.now();
      const modifiedFileName = `${uniqueId}_${file.name}`;
      const modifiedFile = new File([file], modifiedFileName, {
        type: file.type,
      });
      uploadDeliveryRequestFile(modifiedFile, clearFileInput);
    }
  };

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleGoBackButton = () => {
    handleSelectedTab(AdminTabs.CAMPAIGN_CRITERIA);
    navigate(pageRoutes.admin);
  };

  return (
    <Stack spacing={5}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <CustomIconButton
          buttonTitle="Go Back"
          textAndIconColor="black"
          onhoverTextAndIconColor="warning.main"
          variant="text"
          onClick={handleGoBackButton}
          icon={<ArrowBackRoundedIcon fontSize="small" />}
        />
        <Stack justifyContent={"end"} spacing={2} direction={"row"}>
          <CustomIconButton
            buttonTitle="Sample Template"
            buttonColor="#FAF0E1"
            textAndIconColor="warning.main"
            onhoverButtonColor="warning.main"
            onhoverTextAndIconColor="white"
            onClick={() => downloadTemplate()}
            icon={<FileDownloadIcon fontSize="small" />}
          />
          <CustomIconButton
            buttonTitle="Latest Uploaded QPS"
            buttonColor="#FAF0E1"
            textAndIconColor="warning.main"
            onhoverButtonColor="warning.main"
            onhoverTextAndIconColor="white"
            onClick={() => downloadLatestUploadedQPS()}
            icon={<FileDownloadIcon fontSize="small" />}
          />
        </Stack>
      </Stack>
      <InstructionCard />
      <Card>
        <CardContent>
          {loginStatus ? (
            <Stack textAlign={"center"} spacing={3}>
              <Typography variant="h5">Upload Your Excel Sheet</Typography>
              <Button color="primary" component="label">
                <input
                  hidden
                  ref={fileInputRef}
                  type="file"
                  accept=".xlsx, .xls"
                  onChange={handleFileUpload}
                />
                <Stack alignItems={"center"}>
                  <FileUploadRoundedIcon fontSize="large" color="warning" />
                  <Typography
                    textTransform={"none"}
                    variant="h6"
                    fontWeight={550}
                    color={"warning.main"}
                  >
                    Upload your file
                  </Typography>
                </Stack>
              </Button>
            </Stack>
          ) : (
            <Typography variant="h5">
              You can upload excel sheet only through Login. Please Login...
            </Typography>
          )}
        </CardContent>
      </Card>
    </Stack>
  );
};

export default UploadQPSFile;
