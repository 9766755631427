import { Acknowledgement } from "./beneficiary/acknowledgement";
import { Client } from "./client";
import { DeliveryRequest } from "./stakeholder/delivery-request";
import { DeliveryRequestHistoryList } from "./stakeholder/delivery-request-history-list";

type Errors = {
  message?: string;
};

export type Result = {
  history: DeliveryRequestHistoryList[];
  delivery_requests: DeliveryRequest;
  acknowledgements: Acknowledgement;
  success: boolean;
  message: string;
  errors: Errors;
  current_page: number;
  last_page: number;
  total: number;
  otpVerify: string;
  otp: string;

};

export const EmptyResponse: Result = {
  history: [],
  delivery_requests: {} as DeliveryRequest,
  acknowledgements: {} as Acknowledgement,
  success: false,
  message: "",
  otpVerify:"",
  otp:"",
  errors: {} as Errors,
  current_page: 1,
  last_page: 1,
  total: 0,
};
