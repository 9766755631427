import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

type Props = {};

const CampaignList = (props: Props) => {
  return (
    <Card elevation={2}>
      <CardContent>
        <Typography>Campaign Name</Typography>
        <Box
          height={"15vh"}
          overflow={"scroll"}
          paddingRight={3}
          paddingBottom={2}
          marginTop={2}
        >
          <Stack direction={"row"} spacing={10} alignItems={"center"}>
            <Typography fontWeight={550}>SAMRAT</Typography>
            <Stack direction={"row"} spacing={2}>
              <Card
                variant="outlined"
                sx={{
                  width: 150,
                  bgcolor: "#E4AEAE",
                  borderRadius: 1.5,
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Typography variant="caption">Bottle</Typography>
                  <Typography variant="caption">x1</Typography>
                </Stack>
              </Card>
            </Stack>
          </Stack>
          <Divider sx={{ marginY: 2 }} />
          <Stack
            marginTop={3}
            direction={"row"}
            spacing={10}
            alignItems={"center"}
          >
            <Typography fontWeight={550}>VIJETHA</Typography>
            <Stack direction={"row"} spacing={2}>
              <Card
                variant="outlined"
                sx={{
                  width: 150,
                  bgcolor: "#E4AEAE",
                  borderRadius: 1.5,
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Typography variant="caption">Bottle</Typography>
                  <Typography variant="caption">x1</Typography>
                </Stack>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  width: 150,
                  bgcolor: "#E4AEAE",
                  borderRadius: 1.5,
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Typography variant="caption">Bottle</Typography>
                  <Typography variant="caption">x1</Typography>
                </Stack>
              </Card>
              <Card
                variant="outlined"
                sx={{
                  width: 150,
                  bgcolor: "#E4AEAE",
                  borderRadius: 1.5,
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Typography variant="caption">Bottle</Typography>
                  <Typography variant="caption">x1</Typography>
                </Stack>
              </Card>
            </Stack>
          </Stack>
          <Stack
            marginTop={3}
            direction={"row"}
            spacing={10}
            alignItems={"center"}
          >
            <Typography fontWeight={550}>SAMRAT</Typography>
            <Stack direction={"row"} spacing={2}>
              <Card
                variant="outlined"
                sx={{
                  width: 150,
                  bgcolor: "#E4AEAE",
                  borderRadius: 1.5,
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Typography variant="caption">Bottle</Typography>
                  <Typography variant="caption">x1</Typography>
                </Stack>
              </Card>
            </Stack>
          </Stack>

          <Stack
            marginTop={3}
            direction={"row"}
            spacing={10}
            alignItems={"center"}
          >
            <Typography fontWeight={550}>SAMRAT</Typography>
            <Stack direction={"row"} spacing={2}>
              <Card
                variant="outlined"
                sx={{
                  width: 150,
                  bgcolor: "#E4AEAE",
                  borderRadius: 1.5,
                  alignItems: "center",
                  p: 1,
                }}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-around"}
                  alignItems={"center"}
                >
                  <Typography variant="caption">Bottle</Typography>
                  <Typography variant="caption">x1</Typography>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CampaignList;
