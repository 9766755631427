import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/system/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import { FetchState } from "../../../common/enums/fetch-state";
import HistoryCard from "./HistoryCard";
import HistoryLoading from "../loading-skeleton/rep-skeleton/HistoryLoading";
import IMAGES from "../../../assets/images";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY } from "../../../base";
import AppCodeType from "../../../common/enums/appCodeType";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  border: "1px solid",
  height: "80%",
};

type myProps = {
  openModal: boolean;
  handleClose: Function;
};

const HistoryModal: React.FC<myProps> = ({ openModal, handleClose }) => {
  const { deliveryRequestHistoryList, historyFetchState } =
    useDeliveryRequestStore();

  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));

  return (
    <Modal open={openModal} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          margin={2}
        >
          <Typography variant="subtitle2" fontWeight={700}>
            View History
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon color="error" fontSize="small" />
          </IconButton>
        </Stack>
        <Box
          overflow="auto"
          position={"fixed"}
          height="80%"
          width="90%"
          left={20}
        >
          {historyFetchState == FetchState.LOADING && (
            <>
              <HistoryLoading />
              <HistoryLoading />
              <HistoryLoading />
            </>
          )}
          {historyFetchState == FetchState.SUCCESS &&
            deliveryRequestHistoryList.length > 0 &&
            deliveryRequestHistoryList.map((request) => {
              return <HistoryCard request={request} />;
            })}
          {historyFetchState == FetchState.SUCCESS &&
            deliveryRequestHistoryList.length === 0 && (
              <Stack alignItems={"center"} marginTop={2}>
                <img src={IMAGES.NoGift} />
                <Typography variant="h6" fontWeight={600}>
                  No History Found
                </Typography>
              </Stack>
            )}
        </Box>
        <Stack
          position={"fixed"}
          bottom={20}
          width={"100%"}
          paddingTop={2}
          bgcolor="white"
        >
          <Button
            color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
            variant="contained"
            onClick={() => handleClose()}
            sx={{ borderRadius: 20, paddingInline: 5, marginInline: 2 }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={700}
              textTransform="none"
              color="white"
            >
              Go back to QPS Details
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default HistoryModal;
