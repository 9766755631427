import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import IMAGES from "../../../assets/images";
import { FetchState } from "../../../common/enums/fetch-state";
import RequestListSkeleton from "../loading-skeleton/rep-skeleton/RequestListSkeleton";
import DeliveryRequestCard from "./DeliveryRequestCard";
import DeliveryRequestFilter from "./DeliveryRequestFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterModal from "./FilterModal";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY } from "../../../base";
import AppCodeType from "../../../common/enums/appCodeType";

type Props = {};

const DeliveryRequestList = () => {
  const {
    deliveryRequestList,
    inputItems,
    fetchDeliveryRequestList,
    filter,
    fetchState,
    currentPage,
    lastPage,
  } = useDeliveryRequestStore();

  const [isModal, setIsModal] = useState<boolean>(false);

  const handleModal = () => {
    setIsModal(!isModal);
  };

  const handleClose = () => {
    setIsModal(false);
  };

  useEffect(() => {
    fetchDeliveryRequestList(filter.q, 1, false);
  }, []);

  useEffect(() => {
    if (deliveryRequestList.length > 0) {
      inputItems.length = 0;
      for (let i = 0; i <= deliveryRequestList.length - 1; i++) {
        inputItems.push([]);
      }
    }
  }, [deliveryRequestList]);

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };

  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));
  return (
    <Stack gap={2} marginX={2}>
      <Box
        position={"fixed"}
        zIndex={1}
        width="100%"
        sx={{ backgroundColor: "white" }}
        gap={2}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          width="90%"
        >
          <Typography fontWeight={700} variant="body1" marginY={2}>
            QPS Management
          </Typography>
          <Stack spacing={2} direction={"row"} onClick={handleModal} gap={1}>
            <Typography
              color={
                appCode == AppCodeType.CPAISA
                  ? "warning.light"
                  : "secondary.main"
              }
              fontWeight={700}
              variant="subtitle2"
            >
              Filter
            </Typography>
            <FilterListIcon
              fontSize="small"
              color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
            />
          </Stack>
        </Stack>
        <Stack width="90%">
          <DeliveryRequestFilter />
          {deliveryRequestList.length > 0 && (
            <Stack alignItems={"end"}>
              <Typography variant="caption" fontWeight={550}>
                QPS Counts: {deliveryRequestList.length}
              </Typography>
            </Stack>
          )}
          {/* <DeliveryRequestStatusTab /> */}
        </Stack>
      </Box>
      <Box
        id="div"
        height="80vh"
        position={"fixed"}
        width={"90%"}
        marginTop={15}
        overflow="auto"
      >
        {fetchState == FetchState.LOADING && (
          <Stack spacing={2}>
            <RequestListSkeleton />
            <RequestListSkeleton />
            <RequestListSkeleton />
          </Stack>
        )}
        {fetchState == FetchState.SUCCESS && (
          <InfiniteScroll
            scrollableTarget="div"
            dataLength={deliveryRequestList?.length}
            next={() => {
              fetchDeliveryRequestList(filter.q, currentPage + 1, true);
            }}
            hasMore={hasMoreData()}
            loader={<b>Loading...</b>}
          >
            <Stack spacing={2}>
              {deliveryRequestList.length > 0 ? (
                deliveryRequestList.map((data, index: number) => {
                  return (
                    <div key={index}>
                      <DeliveryRequestCard
                        deliveryRequest={data}
                        rowIndex={index}
                      />
                    </div>
                  );
                })
              ) : (
                <Stack alignItems={"center"} marginTop={10}>
                  <img src={IMAGES.NoGift} />
                  <Typography variant="h6" fontWeight={600}>
                    No Records Found
                  </Typography>
                </Stack>
              )}
            </Stack>
          </InfiniteScroll>
        )}
      </Box>
      <FilterModal handleClose={handleClose} openModal={isModal} />
    </Stack>
  );
};

export default DeliveryRequestList;
