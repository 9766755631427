import { Button, Stack, Typography } from "@mui/material";

type Props = {
  buttonTitle?: string;
  textAndIconColor?: string;
  onhoverTextAndIconColor?: string;
  onhoverButtonColor?: string;
  buttonColor?: string;
  icon?: React.ReactNode;
  variant?: "contained" | "text" | "outlined";
  onClick: Function;
};

const CustomIconButton: React.FC<Props> = ({
  buttonTitle,
  buttonColor,
  textAndIconColor,
  onhoverButtonColor,
  onhoverTextAndIconColor,
  icon,
  variant = "contained",
  onClick,
}) => {
  return (
    <Button
      variant={variant}
      onClick={() => onClick()}
      sx={{
        borderRadius: 10,
        color: textAndIconColor,
        backgroundColor: buttonColor,
        ":hover": {
          bgcolor: onhoverButtonColor,
          color: onhoverTextAndIconColor,
        },
      }}
    >
      <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {icon}
        <Typography variant="subtitle2" fontWeight={550} textTransform={"none"}>
          {buttonTitle}
        </Typography>
      </Stack>
    </Button>
  );
};

export default CustomIconButton;
