import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/system/Stack";
import Radio from "@mui/material/Radio";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  RadioGroup,
  TextField,
} from "@mui/material";
import Status from "../../../common/enums/status";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Dayjs } from "dayjs";
import moment from "moment";
import { Constants } from "../../../common/constant";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY } from "../../../base";
import AppCodeType from "../../../common/enums/appCodeType";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 280,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

type myProps = {
  openModal: boolean;
  handleClose: Function;
};

const FilterModal: React.FC<myProps> = ({ openModal, handleClose }) => {
  const { filter, handleAppliedFilters, showFilter } =
    useDeliveryRequestStore();
  const [status, setStatus] = React.useState("");
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(null);
  const [toDate, setToDate] = React.useState<Dayjs | null>(null);

  const handleStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value);
    filter.status = event.target.value;
  };

  useEffect(() => {
    if (!showFilter) {
      setStatus("");
    }
  }, [showFilter]);

  const clearFilters = () => {
    setStatus("");
    setFromDate(null);
    setToDate(null);
    filter.status = "";
    filter.to_date = "";
    filter.from_date = "";
    handleAppliedFilters();
    handleClose();
  };

  const handleToDateChange = (value: Dayjs | null) => {
    setToDate(value);
    filter.to_date = moment((value as Dayjs).toString()).format(
      Constants.YEAR_MONTH_DATE_FORMAT
    );
  };

  const handleFromDateChange = (value: Dayjs | null) => {
    setFromDate(value);
    filter.from_date = moment((value as Dayjs).toString()).format(
      Constants.YEAR_MONTH_DATE_FORMAT
    );
  };

  const onApplyFilters = () => {
    handleAppliedFilters();
    handleClose();
  };
  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));
  return (
    <Stack>
      <Modal open={openModal} onClose={() => handleClose()}>
        <Box sx={style}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography variant="subtitle1" fontWeight={700}>
              Filters
            </Typography>
            <Stack
              direction="row"
              alignItems={"center"}
              pt={1}
              pr={1}
              justifyContent="end"
            >
              <IconButton onClick={() => handleClose()}>
                <CloseIcon color="error" fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>

          <FormControl>
            <Typography mt={3} variant="subtitle2" fontWeight={700}>
              Status
            </Typography>
            <RadioGroup value={status} onChange={handleStatusChange}>
              <Grid container marginBottom={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    value={Status.PENDING}
                    control={
                      <Radio
                        color={
                          appCode == AppCodeType.CPAISA
                            ? "warning"
                            : "secondary"
                        }
                      />
                    }
                    label={<Typography variant="subtitle2">Pending</Typography>}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value={Status.FULFILLED}
                    control={
                      <Radio
                        color={
                          appCode == AppCodeType.CPAISA
                            ? "warning"
                            : "secondary"
                        }
                      />
                    }
                    label={
                      <Typography variant="subtitle2">Fulfilled</Typography>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value={Status.REJECTED}
                    control={
                      <Radio
                        color={
                          appCode == AppCodeType.CPAISA
                            ? "warning"
                            : "secondary"
                        }
                      />
                    }
                    label={
                      <Typography variant="subtitle2">Rejected</Typography>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value={Status.CANCELED}
                    control={
                      <Radio
                        color={
                          appCode == AppCodeType.CPAISA
                            ? "warning"
                            : "secondary"
                        }
                      />
                    }
                    label={
                      <Typography variant="subtitle2">Canceled</Typography>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value={Status.ACKNOWLEDGEMENT_RAISED}
                    control={
                      <Radio
                        color={
                          appCode == AppCodeType.CPAISA
                            ? "warning"
                            : "secondary"
                        }
                      />
                    }
                    label={
                      <Typography variant="subtitle2">
                        Acknowledgment Raised
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value={Status.PARTIAL}
                    control={
                      <Radio
                        color={
                          appCode == AppCodeType.CPAISA
                            ? "warning"
                            : "secondary"
                        }
                      />
                    }
                    label={
                      <Typography variant="subtitle2">
                        Partially Fulfilled
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>
          <Typography mt={2} mb={2} variant="body2" fontWeight={700}>
            Date
          </Typography>

          <Stack direction="row" spacing={2} mb={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                InputProps={{ size: "small" }}
                label="From Date"
                disableFuture
                value={fromDate}
                onChange={(newValue) => {
                  handleFromDateChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color={
                      appCode == AppCodeType.CPAISA ? "warning" : "secondary"
                    }
                    focused
                    size="small"
                    placeholder="From Date"
                  />
                )}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                InputProps={{ size: "small" }}
                label="To Date"
                disabled={fromDate == null}
                value={toDate}
                disableFuture
                minDate={fromDate}
                onChange={(newValue) => {
                  handleToDateChange(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color={
                      appCode == AppCodeType.CPAISA ? "warning" : "secondary"
                    }
                    focused
                    size="small"
                    placeholder="To Date"
                  />
                )}
              />
            </LocalizationProvider>
          </Stack>
          <Divider />

          <Stack direction="row" mt={2} spacing={2} justifyContent="center">
            <Button
              variant="outlined"
              color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
              onClick={clearFilters}
            >
              <Typography textTransform="capitalize" variant="caption">
                {" "}
                Clear all filters{" "}
              </Typography>
            </Button>

            <Button
              variant="contained"
              color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
              onClick={onApplyFilters}
              disabled={status == "" && (toDate == null || fromDate == null)}
            >
              <Typography
                textTransform="capitalize"
                variant="caption"
                color="white"
              >
                Apply filters
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Stack>
  );
};

export default FilterModal;
