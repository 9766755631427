import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import CampaignDetailsCard from "./CampaignDetailsCard";
import { CampaignCriteria } from "../../../domain/models/admin/campaignCriteria";
import moment from "moment";

type Props = {
  campaignDetails: CampaignCriteria;
};

const CampaignCriteriaCard: React.FC<Props> = ({ campaignDetails }) => {
  let regex = /(^|\s)\S/g;
  return (
    <Box marginBottom={1}>
      <Card sx={{ bgcolor: "#FAF8F9", borderRadius: 5, width: 400 }}>
        <CardHeader
          title={
            <Typography variant="body1" fontWeight={550}>
              {campaignDetails.campaign_name
                ?.toLowerCase()
                .replace(regex, (letter) => letter.toUpperCase())}
            </Typography>
          }
          subheader={
            <Typography variant="caption">
              {moment(campaignDetails.start_date).format("ll")} -{" "}
              {moment(campaignDetails.end_date).format("ll")}
            </Typography>
          }
        />
        <CardContent>
          <Box
            height={"55vh"}
            overflow={"scroll"}
            paddingRight={3}
            paddingBottom={2}
          >
            <Stack spacing={2}>
              {campaignDetails.criteria &&
              campaignDetails.criteria.length > 0 ? (
                campaignDetails.criteria.map((criteria) => {
                  return <CampaignDetailsCard criteriaDetails={criteria} />;
                })
              ) : (
                <Typography variant="subtitle2" fontWeight={550}>
                  No Criteria Available
                </Typography>
              )}
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CampaignCriteriaCard;
