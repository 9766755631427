import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#14008D",
    },
    warning: {
      main: "#FF9600",
    },
    info: {
      main: "#6B6B6B",
      light: "#D1CFCB",
      contrastText: "#FAFAFA",
    },
    error: {
      main: "#FC6276",
    },
  },
});

export default theme;
