import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY } from "../../../base";
import AppCodeType from "../../../common/enums/appCodeType";

type Props = {
  successCallback: Function;
};

const ClockTimer: React.FC<Props> = ({ successCallback }) => {
  const [seconds, setSeconds] = useState<number>(30);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (seconds <= 0) {
      successCallback(true);
    }
  }, [seconds]);
  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));
  return (
    <Typography
      variant="caption"
      color={appCode == AppCodeType.CPAISA ? "warning.main" : "secondary.main"}
      fontWeight={550}
    >
      You can resend OTP in {seconds}s
    </Typography>
  );
};

export default ClockTimer;
