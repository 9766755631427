import {
  Button,
  debounce,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";

type Props = {};

const DeliveryRequestFilter = (props: Props) => {
  const { filter, fetchDeliveryRequestList } = useDeliveryRequestStore();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    filter.q = e.target.value;
    fetchDeliveryRequestList(filter.q, 1, false);
  };
  const debouncedOnChange = debounce(handleSearch, 2000);
  return (
    <Stack direction={"row"} gap={1}>
      <TextField
        fullWidth
        size="small"
        onChange={debouncedOnChange}
        placeholder="Enter Customer Name"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

export default DeliveryRequestFilter;
