import { Box, Divider, Stack, Typography } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import IMAGES from "../../../../assets/images";
import { FetchState } from "../../../../common/enums/fetch-state";
import AcknowledgementListSkeleton from "../../loading-skeleton/customer-skeleton/AcknowledgementListSkeleton";
import AcknowledgementCard from "./AcknowledgementCard";
import { useBeneficiaryStore } from "../../../../store/main/beneficiaryStore";

const AcknowledgementList = () => {
  const {
    fetchAcknowledgements,
    acknowledgementList,
    fetchState,
    currentPage,
    lastPage,
    filter,
  } = useBeneficiaryStore();

  const hasMoreData = () => {
    return fetchState == FetchState.SUCCESS ? currentPage < lastPage : false;
  };
  return (
    <>
      <Box
        id="div"
        height="80vh"
        position={"fixed"}
        width={"90%"}
        overflow="auto"
      >
        {fetchState == FetchState.LOADING && (
          <Stack spacing={2}>
            <AcknowledgementListSkeleton />
            <AcknowledgementListSkeleton />
            <AcknowledgementListSkeleton />
            <AcknowledgementListSkeleton />
          </Stack>
        )}
        {fetchState == FetchState.SUCCESS && (
          <InfiniteScroll
            scrollableTarget="div"
            dataLength={acknowledgementList?.length}
            next={() => {
              fetchAcknowledgements(currentPage + 1, true, filter);
            }}
            hasMore={hasMoreData()}
            loader={<b>Loading...</b>}
          >
            <Stack>
              {acknowledgementList.length > 0 ? (
                acknowledgementList.map((data) => {
                  return (
                    <Stack marginBottom={1}>
                      <AcknowledgementCard data={data} />
                      <Divider />
                    </Stack>
                  );
                })
              ) : (
                <Stack alignItems={"center"} marginTop={10}>
                  <img src={IMAGES.NoGift} />
                  <Typography variant="h6" fontWeight={600}>
                    No Acknowledgements Found
                  </Typography>
                </Stack>
              )}
            </Stack>
          </InfiniteScroll>
        )}
      </Box>
    </>
  );
};

export default AcknowledgementList;
