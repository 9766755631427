import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

type Props = {};

const InstructionCard = (props: Props) => {
  return (
    <Card elevation={0} raised>
      <CardContent>
        <Typography variant="subtitle2" fontWeight={550}>
          Important Instructions:
        </Typography>
        <Typography variant="caption">
          To complete the task, you should follow these steps:
        </Typography>
        <List
          disablePadding
          sx={{
            listStyleType: "disc",
            pl: 4,
            "& .MuiListItem-root": {
              display: "list-item",
            },
          }}
        >
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="caption">
                  Click on "Download sample template" to obtain the template.
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="caption">
                  Fill necessary data in the template.
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="caption">
                  Save the template in the same format as it was downloaded.
                </Typography>
              }
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary={
                <Typography variant="caption">
                  Upload the filled template in the appropriate location,
                  following any provided instructions or guidelines, to ensure
                  the data is submitted correctly.
                </Typography>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default InstructionCard;
