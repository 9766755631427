import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
} from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { RemoteDownloadQPSCustomerReport } from "../../../data/usecases/stakeholder/remote-download-qps-customer-report";
import { DownloadQPSCustomerReport } from "../../../domain/usages/stakeholder/download-qps-customer-report";
import moment from "moment";
import { Dayjs } from "dayjs";

interface DownloadCustomerReportInterface {
  downloadCustomerReport: Function;
  handleDownloadCustomerReportStatus: Function;
}

type FormFields = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  orgCode: string;
};

const initialState = {};

export const useDownloadCustomerReportSlice: StateCreator<
  DownloadCustomerReportInterface
> = (set, get) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    handleDownloadCustomerReportStatus: (
      value: boolean,
      text?: string,
      status?: "success" | "error"
    ) => {
      if (value === true) {
        Swal.fire({
          title: "Please wait... Report is getting downloaded",
          didOpen: () => {
            Swal.showLoading();
          },
        });
      } else {
        Swal.fire({
          icon: status,
          title: text,
          timer: 2000,
        });
      }
    },
    downloadCustomerReport: async (data: FormFields) => {
      const token = storage.get(AUTH_TOKEN_KEY);
      const appCode = storage.get(APP_CODE_KEY);
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: atob(token),
        [APP_CODE]: atob(appCode),
      });
      const remoteDownloadQPSCustomerReport: DownloadQPSCustomerReport =
        new RemoteDownloadQPSCustomerReport(
          `${INPUT_SERVICE_API_URL}${Endpoints.DOWNLOAD_QPS_CUSTOMER_REPORT}`,
          axiosHttpClient
        );
      let filters = {
        start_date: moment((data.startDate as Dayjs).toString()).format(
          "YYYY-MM"
        ),
        end_date: moment(
          (data.endDate as Dayjs).endOf("month").toString()
        ).format("YYYY-MM"),
        organisation_code: data.orgCode,
      };

      try {
        get().handleDownloadCustomerReportStatus(true);
        const response = await remoteDownloadQPSCustomerReport.download(
          filters
        );
        if (response) {
          const excelBlob = new Blob([response], { type: "application/xlsx" });
          const url = window.URL.createObjectURL(excelBlob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "QPSCustomerReport.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          get().handleDownloadCustomerReportStatus(
            false,
            "Downloaded Successfully!",
            "success"
          );
        } else {
          get().handleDownloadCustomerReportStatus(
            false,
            "Download Failed!",
            "error"
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
  };
};
