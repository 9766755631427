import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
} from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { DeliveryRequestsBulkUpload } from "../../../domain/usages/stakeholder/delivery-requests-bulk-upload";
import { RemoteDeliveryRequestsBulkUpload } from "../../../data/usecases/stakeholder/remote-delivery-requests-bulk-upload";
import { Result } from "../../../domain/models/result";

interface DeliveryRequestBulkUploadInterface {
  uploadDeliveryRequestFile: Function;
  handleFileUploadStatus: Function;
}

const initialState = {};

export const useDeliveryRequestBulkUpload: StateCreator<
  DeliveryRequestBulkUploadInterface
> = (set, get) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    handleFileUploadStatus: (
      value: boolean,
      text?: string,
      status?: "success" | "error"
    ) => {
      if (value === true) {
        Swal.fire({
          title: "Please wait... Excel Sheet is getting uploaded",
          didOpen: () => {
            Swal.showLoading();
          },
        });
      } else {
        Swal.fire({
          icon: status,
          title: text,
          timer: 3000,
        });
      }
    },
    uploadDeliveryRequestFile: async (file: File, clearFileInput: Function) => {
      const token = storage.get(AUTH_TOKEN_KEY);
      const appCode = storage.get(APP_CODE_KEY);
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: atob(token),
        [APP_CODE]: atob(appCode),
      });
      const remoteDownloadQPSTemplate: DeliveryRequestsBulkUpload =
        new RemoteDeliveryRequestsBulkUpload(
          `${INPUT_SERVICE_API_URL}${Endpoints.DELIVERY_REQUESTS_BULK_UPLOAD}`,
          axiosHttpClient
        );

      try {
        if (file) {
          get().handleFileUploadStatus(true);

          let payload = {
            file: file,
          };
          const result: Result = await remoteDownloadQPSTemplate.upload(
            payload
          );
          if (result.success) {
            clearFileInput();
            get().handleFileUploadStatus(false, result.success, "success");
          } else {
            get().handleFileUploadStatus(false, "Uploaded Failed!", "error");
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  };
};
