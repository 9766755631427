import {
  APP_CODE_KEY,
  AUTH_TOKEN_KEY,
  CONFIRM_LOGIN_KEY,
  UUID_KEY,
  LONGITUDE_KEY,
  LATITUDE_KEY,
} from "../../base";
import { LoggedInUser } from "../../domain/usages/logged-in-user";
import { JsonStorage } from "../protocols/storage/json-storage";
export class LocalLoggedInUser implements LoggedInUser {
  tokenKey: string = AUTH_TOKEN_KEY;
  appCodeKey: string = APP_CODE_KEY;
  latKey: string = LATITUDE_KEY;
  longKey: string = LONGITUDE_KEY;
  uuidKey: string = UUID_KEY;
  loggedInKey: string = CONFIRM_LOGIN_KEY;

  constructor(private readonly jsonStorage: JsonStorage) {}

  getToken(): string | null {
    let token = this.jsonStorage.get(this.tokenKey);
    if (token && token !== "") return token;
    return null;
  }

  getLoggedIn(): boolean | null {
    let loggedIn = this.jsonStorage.get(this.loggedInKey);
    return loggedIn;
  }
  getAppCode(): string | null {
    let appCode = this.jsonStorage.get(this.appCodeKey);
    if (appCode && appCode !== "") return appCode;
    return null;
  }
  getlatitude(): string | null {
    let lat = this.jsonStorage.get(this.latKey);
    if (lat && lat !== "") return lat;
    return null;
  }
  getlongitude(): string | null {
    let long = this.jsonStorage.get(this.longKey);
    if (long && long !== "") return long;
    return null;
  }

  getUUID(): string | null {
    let uuid = this.jsonStorage.get(this.uuidKey);
    if (uuid && uuid !== "") return uuid;
    return null;
  }

  setToken(token: string): void {
    this.jsonStorage.add(this.tokenKey, token);
  }

  setLoggedIn(value: boolean): void {
    this.jsonStorage.add(this.loggedInKey, value);
  }
  setUUID(uuid: string): void {
    this.jsonStorage.add(this.uuidKey, uuid);
  }

  setAppCode(appCode: string): void {
    this.jsonStorage.add(this.appCodeKey, appCode);
  }
  setlatitude(lat: string): void {
    this.jsonStorage.add(this.latKey, lat);
  }
  setLongitude(long: string): void {
    this.jsonStorage.add(this.longKey, long);
  }
  logout(): void {
    this.jsonStorage.remove(this.tokenKey);
    this.jsonStorage.remove(this.appCodeKey);
    this.jsonStorage.remove(this.uuidKey);
    this.jsonStorage.remove(this.longKey);
    this.jsonStorage.remove(this.latKey);
  }
}
