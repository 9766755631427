import { Typography, Stack } from "@mui/material";
import React from "react";

type Props = {
  title: string;
};
const Header: React.FC<Props> = ({ title }) => {
  return (
    <Typography variant="subtitle2" fontWeight={700}>
      {title}
    </Typography>
  );
};

export default Header;
