import { Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { LoginFormFields } from "../../../domain/models/auth/loginFormFields";
import { useLoginStore } from "../../../store/main/loginStore";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../../../routes";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { LocalLoggedInUser } from "../../../data/usecases/local-logged-in-user";

type Props = {};

const LoginForm = (props: Props) => {
  const { handleSubmit, control, watch } = useForm<LoginFormFields>({
    mode: "onChange",
  });

  const { verifyLoginDetails, loading } = useLoginStore();
  const navigate = useNavigate();
  const jsonStorage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(jsonStorage);

  const onSubmit = (data: LoginFormFields) => {
    verifyLoginDetails(data, handleNavigation);
  };

  const handleNavigation = (value: boolean) => {
    if (value) {
      loggedInUser.setLoggedIn(value);
      navigate(pageRoutes.admin);
    }
  };
  return (
    <Stack spacing={2} width={"60%"}>
      <Controller
        name="userName"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="Username"
            placeholder="Enter username..."
            value={value}
            onChange={onChange}
          />
        )}
        rules={{
          required: { value: true, message: "Field Required" },
        }}
      />
      <Controller
        name="password"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            label="Password"
            disabled={watch("userName") ? false : true}
            placeholder="Enter password..."
            value={value}
            onChange={onChange}
            type="password"
          />
        )}
        rules={{
          required: { value: true, message: "Field Required" },
        }}
      />
      <LoadingButton
        loading={loading}
        disabled={watch("password") ? false : true}
        variant="contained"
        color="warning"
        onClick={handleSubmit(onSubmit)}
      >
        <Typography textTransform={"none"} color="white">
          Login
        </Typography>
      </LoadingButton>
    </Stack>
  );
};

export default LoginForm;
