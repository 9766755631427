import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { LocalLoggedInUser } from "../../data/usecases/local-logged-in-user";
import { LoggedInUserDetails } from "../../domain/models/loggedInUserDetails";
import { LocalJsonStorage } from "../../infra/http/local-json-storage";
import jwt_decode from "jwt-decode";
import { pageRoutes } from "../../routes";
import { Stack, Typography } from "@mui/material";
import IMAGES from "../../assets/images";

type Props = {};

const AuthenticationPage = (props: Props) => {
  const { authToken } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const jsonStorage = LocalJsonStorage.getInstance();
  const loggedInUser = new LocalLoggedInUser(jsonStorage);
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    loggedInUser.logout();
    if (authToken) {
      loggedInUser.setToken(btoa(authToken));
      const loggedInUserDetails: LoggedInUserDetails = jwt_decode(authToken);
      if (
        searchParams.get("uuid") ||
        (searchParams.get("appCode") &&
          searchParams.get("lat") &&
          searchParams.get("long"))
      ) {
        loggedInUser.setUUID(searchParams.get("uuid") as string);
        loggedInUser.setAppCode(btoa(searchParams.get("appCode") as string));
        loggedInUser.setlatitude(searchParams.get("lat") as string);
        loggedInUser.setLongitude(searchParams.get("long") as string);
        if (loggedInUserDetails.role === "CHANNEL_PARTNER") {
          navigate(pageRoutes.acknowledgementList);
        } else {
          navigate(pageRoutes.requestDeliveryList);
        }
      } else {
        setError(true);
      }
    }
  }, [authToken]);
  return (
    <>
      {error && (
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          margin={5}
          height="95%"
        >
          <img src={IMAGES.Error} />
          <Typography variant="subtitle2" fontWeight={600}>
            Something went wrong. Please contact the support team or raise
            ticket.
          </Typography>
        </Stack>
      )}
    </>
  );
};

export default AuthenticationPage;
