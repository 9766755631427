import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AdminTabs from "../../../common/enums/adminTabs";
import {
  DisableSubmitButton,
  showAppCodeField,
  showDivisionFilter,
  showEndMonthAndYearFilter,
  showOrganizationFilter,
  showStartMonthAndYearFilter,
} from "../../../common/utils";
import { useEffect, useState } from "react";
import { ReportFilters } from "../../../domain/models/admin/report-filters";
import { Dayjs } from "dayjs";
import { useAdminStore } from "../../../store/main/adminStore";

type Props = {
  columns?: number;
  width?: string;
  type: string;
  successCallback: Function;
};

const QPSAdminFilters: React.FC<Props> = ({
  columns = 12,
  width = "100%",
  type,
  successCallback,
}) => {
  const { appCodes, fetchAppCodes } = useAdminStore();

  const [filterInputValues, setFilterInputValues] = useState<ReportFilters>({
    appCode: "",
    organization: "",
    division: "",
    startDate: null,
    endDate: null,
  });

  const clearAll = () => {
    const filters = { ...filterInputValues };
    filters.appCode = "";
    filters.division = "";
    filters.endDate = null;
    filters.organization = "";
    filters.startDate = null;
    setFilterInputValues(filters);
    successCallback(filters);
  };

  const onSubmitFilters = () => {
    successCallback(filterInputValues);
  };

  const handleDateChange = (event: Dayjs | null, name: string) => {
    const filters = { ...filterInputValues };
    if (event && name == "startDate") {
      filters.startDate = event;
    } else if (event && name == "endDate") {
      filters.endDate = event;
    }
    setFilterInputValues(filters);
  };

  const handleSelectChange = (event: SelectChangeEvent, name: string) => {
    const filters = { ...filterInputValues };
    if (name === "appCode") {
      filters.appCode = event.target.value;
    } else if (name === "organization") {
      filters.organization = event.target.value;
    } else if (name === "division") {
      filters.division = event.target.value;
    }
    setFilterInputValues(filters);
  };

  return (
    <Grid container columns={columns} spacing={2} width={width}>
      {showAppCodeField(type) && (
        <Grid item xs={3.5}>
          <FormControl
            fullWidth
            size="small"
            sx={{ borderRadius: 2, color: "warning.main" }}
          >
            <InputLabel color="warning">App Code</InputLabel>
            <Select
              value={filterInputValues.appCode}
              label="App Code"
              size="small"
              color="warning"
              onChange={(e) => handleSelectChange(e, "appCode")}
              sx={{ borderRadius: 2 }}
            >
              {appCodes.map((appCode) => {
                return <MenuItem value={appCode.code}>{appCode.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      {showOrganizationFilter(type) && (
        <Grid item xs={3}>
          <FormControl
            fullWidth
            size="small"
            sx={{ borderRadius: 2, color: "warning.main" }}
          >
            <InputLabel color="warning">Organization</InputLabel>
            <Select
              label="Organization"
              size="small"
              color="warning"
              value={filterInputValues.organization}
              onChange={(e) => handleSelectChange(e, "organization")}
              sx={{ borderRadius: 2 }}
            >
              <MenuItem value={10}>CPAISA</MenuItem>
              <MenuItem value={20}>CPAY</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {showDivisionFilter(type) && (
        <Grid item xs={3}>
          <FormControl
            fullWidth
            size="small"
            sx={{ borderRadius: 2, color: "warning.main" }}
          >
            <InputLabel color="warning">Divison</InputLabel>
            <Select
              label="Divison"
              size="small"
              value={filterInputValues.division}
              color="warning"
              onChange={(e) => handleSelectChange(e, "division")}
              sx={{ borderRadius: 2 }}
            >
              <MenuItem value={10}>CPAISA</MenuItem>
              <MenuItem value={20}>CPAY</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      {showStartMonthAndYearFilter(type) && (
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Month & Year"
              views={["month", "year"]}
              value={filterInputValues.startDate}
              onChange={(e) => handleDateChange(e, "startDate")}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Select Date"
                  size="small"
                  color="warning"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      )}
      {showEndMonthAndYearFilter(type) && (
        <Grid item xs={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="End Month & Year"
              views={["month", "year"]}
              disabled={filterInputValues.startDate === null}
              minDate={filterInputValues.startDate}
              value={filterInputValues.endDate}
              onChange={(e) => handleDateChange(e, "endDate")}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Select Date"
                  size="small"
                  fullWidth
                  color="warning"
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      )}
      <Grid item xs={3}>
        <Stack spacing={1} direction={"row"}>
          <Button
            variant="contained"
            color="warning"
            onClick={onSubmitFilters}
            disabled={DisableSubmitButton(filterInputValues)}
          >
            <Typography textTransform={"none"} fontWeight={550} color="white">
              Submit
            </Typography>
          </Button>
          <Button
            color="warning"
            onClick={clearAll}
            disabled={DisableSubmitButton(filterInputValues)}
          >
            <Typography textTransform={"none"} fontWeight={550}>
              Clear
            </Typography>
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default QPSAdminFilters;
