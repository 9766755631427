import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
} from "../../../base";
import { FetchState } from "../../../common/enums/fetch-state";
import Endpoints from "../../../domain/endpoints";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { RemoteFetchAppCodes } from "../../../data/usecases/admin/remote-fetch-app-codes";
import { FetchAppCodes } from "../../../domain/usages/admin/fetch-app-codes";
import { AppCodes } from "../../../domain/models/admin/appCodes";

interface AppCodesInterface {
  appCodes: AppCodes[];
  appCodeLoading: FetchState;
  fetchAppCodes: Function;
}

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

const initialState = {
  appCodeLoading: FetchState.DEFAULT,
  appCodes: [],
};

export const useAppCodesSlice: StateCreator<AppCodesInterface> = (set, get) => {
  {
    return {
      ...initialState,
      fetchAppCodes: async () => {
        const token = storage.get(AUTH_TOKEN_KEY);
        const appCode = storage.get(APP_CODE_KEY);

        axiosHttpClient.setAuthHeaders({
          [AUTH_HEADER]: atob(token),
          [APP_CODE]: atob(appCode),
        });
        const remoteFetchAppCodes: FetchAppCodes = new RemoteFetchAppCodes(
          `${INPUT_SERVICE_API_URL}${Endpoints.FETCH_APP_CODES}`,
          axiosHttpClient
        );

        set(() => ({
          appCodeLoading: FetchState.LOADING,
        }));
        let result = await remoteFetchAppCodes.fetch();
        if (result.success) {
          set(() => ({
            appCodes: result.projects,
            appCodeLoading: FetchState.SUCCESS,
          }));
        }
      },
    };
  }
};
