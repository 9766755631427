import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import OTPModes from "../../common/enums/OTPModes";
import AppCodeType from "../../common/enums/appCodeType";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TextsmsIcon from "@mui/icons-material/Textsms";

type Props = {
  handleOTPModeChange: Function;
  OTPMode: String;
  appCode: String;
  message?: string;
};

const OTPModeSelect: React.FC<Props> = ({
  handleOTPModeChange,
  OTPMode,
  appCode,
  message,
}) => {
  const handleChangeOTPMode = (mode: string) => {
    handleOTPModeChange(mode);
  };
  return (
    <FormControl>
      <FormLabel>
        <Typography variant="caption" color="black">
          {message}
        </Typography>
      </FormLabel>
      <Stack
        alignItems={"center"}
        direction={"row"}
        justifyContent={"center"}
        spacing={2}
        marginTop={1}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          spacing={0.3}
          justifyContent={"center"}
          border={
            OTPMode == OTPModes.SMS
              ? "1px solid warning.main"
              : "1px solid gray"
          }
          borderRadius={1}
          p={0.5}
          bgcolor={OTPMode == OTPModes.SMS ? "warning.main" : "white"}
          onClick={() => handleChangeOTPMode(OTPModes.SMS)}
        >
          <TextsmsIcon
            sx={{
              fontSize: "15px",
              color: OTPMode == OTPModes.SMS ? "white" : "warning.main",
            }}
          />
          <Typography
            variant="caption"
            fontWeight={660}
            color={OTPMode == OTPModes.SMS ? "white" : "warning.main"}
          >
            SMS
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          spacing={0.3}
          border={
            OTPMode == OTPModes.WHATSAPP
              ? "1px solid warning.main"
              : "1px solid gray"
          }
          borderRadius={1}
          p={0.5}
          bgcolor={OTPMode == OTPModes.WHATSAPP ? "warning.main" : "white"}
          onClick={() => handleChangeOTPMode(OTPModes.WHATSAPP)}
        >
          <WhatsAppIcon
            sx={{
              fontSize: "15px",
              color: OTPMode == OTPModes.WHATSAPP ? "white" : "warning.main",
            }}
          />
          <Typography
            variant="caption"
            fontWeight={660}
            color={OTPMode == OTPModes.WHATSAPP ? "white" : "warning.main"}
          >
            Whatsapp
          </Typography>
        </Stack>

        {/* <RadioGroup row onChange={handleChangeOTPMode} value={OTPMode}>
          <FormControlLabel
            value={OTPModes.SMS}
            control={
              <Radio
                size="small"
                color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
              />
            }
            label={
              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <TextsmsIcon sx={{ fontSize: "15px" }} />
                <Typography variant="caption">SMS</Typography>
              </Stack>
            }
          />
          <FormControlLabel
            value={OTPModes.WHATSAPP}
            control={
              <Radio
                size="small"
                color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
              />
            }
            label={
              <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
                <WhatsAppIcon sx={{ fontSize: "15px" }} color="success" />
                <Typography variant="caption">Whatsapp</Typography>
              </Stack>
            }
          />
        </RadioGroup> */}
      </Stack>
    </FormControl>
  );
};

export default OTPModeSelect;
