import { Skeleton, Stack } from "@mui/material";

type Props = {};

const FiltersLoading = (props: Props) => {
  return (
    <Stack spacing={1} paddingX={1} direction={"row"}>
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={50}
        width={150}
      />

      <Skeleton
        variant="rectangular"
        animation="wave"
        height={50}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={50}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={50}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={50}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={50}
        width={150}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={50}
        width={150}
      />
    </Stack>
  );
};

export default FiltersLoading;
