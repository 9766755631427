import { Tab, Typography, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { FetchState } from "../../../../common/enums/fetch-state";
import Status from "../../../../common/enums/status";
import { useBeneficiaryStore } from "../../../../store/main/beneficiaryStore";

const AcknowledgementStatusTabs = () => {
  const { filter, handleAppliedFilters, handleShowFilter, total, fetchState } =
    useBeneficiaryStore();
  const [value, setValue] = useState("");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue) {
      handleShowFilter(false);
    } else {
      handleShowFilter(true);
    }
    setValue(newValue);
    filter.status = newValue;
    handleAppliedFilters();
  };

  useEffect(() => {
    filter.status = Status.PENDING;
    setValue(Status.PENDING);
    handleAppliedFilters();
  }, []);

  return (
    <Box>
      <Tabs
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        centered
        value={value}
        onChange={handleChange}
      >
        <Tab
          label={
            <Typography variant="caption">{`Pending ${
              value == Status.PENDING && fetchState == FetchState.SUCCESS
                ? `(${total})`
                : ""
            }`}</Typography>
          }
          value={Status.PENDING}
        />
        <Tab label={<Typography variant="caption">All</Typography>} value="" />
      </Tabs>
    </Box>
  );
};

export default AcknowledgementStatusTabs;
