import { TextareaAutosize, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { useEffect } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import "../../../App.css";
import { RequestItem } from "../../../domain/models/stakeholder/request-item";
import Status from "../../../common/enums/status";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import AppCodeType from "../../../common/enums/appCodeType";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY } from "../../../base";

type Props = {
  showAddItems: boolean;
  item: RequestItem;
  columnIndex: number;
  rowIndex: number;
};

const DeliveryItemCard: React.FC<Props> = ({
  showAddItems = false,
  item,
  columnIndex,
  rowIndex,
}) => {
  const {
    deliveryRequestList,
    inputItems,
    handleIncrementinputItems,
    handleInputItems,
    handleChangeInputItemsQuantity,
    handleDecrementinputItems,
  } = useDeliveryRequestStore();

  useEffect(() => {
    if (showAddItems) {
      handleInputItems(item, rowIndex, columnIndex);
    }
  }, [item.delivery_request_id, showAddItems]);

  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));

  return (
    <Stack
      padding={1}
      bgcolor="info.contrastText"
      borderRadius={2}
      minWidth={"95%"}
      direction="row"
      justifyContent={"space-between"}
      alignItems={"center"}
      spacing={2}
      marginRight={2}
      marginBottom={3}
    >
      <Stack>
        <Typography variant="caption" fontWeight={550}>
          QPS : {item.campaign_input.name}
        </Typography>
        <Typography
          variant="caption"
          color={
            appCode == AppCodeType.CPAISA ? "warning.main" : "secondary.main"
          }
          fontWeight={700}
        >
          Total Qty : {`${item.total_quantity}`}
        </Typography>
        <Typography
          variant="caption"
          color={
            appCode == AppCodeType.CPAISA ? "warning.main" : "secondary.main"
          }
          fontWeight={700}
        >
          Acknowledged Qty : {`${item.acknowledged_quantity}`}
        </Typography>
        <Typography
          variant="caption"
          color={
            appCode == AppCodeType.CPAISA ? "warning.main" : "secondary.main"
          }
          fontWeight={700}
        >
          Pending Qty : {`${item.total_quantity - item.acknowledged_quantity}`}
        </Typography>
      </Stack>
      {showAddItems &&
        (deliveryRequestList[rowIndex].reward_status == Status.PENDING ||
          deliveryRequestList[rowIndex].reward_status == Status.PARTIAL) && (
          <Stack
            direction={"row"}
            width={"30%"}
            height={40}
            alignItems="center"
            justifyContent="space-between"
            borderRadius={15}
            paddingX={2}
            bgcolor={
              appCode == AppCodeType.CPAISA ? "warning.main" : "secondary.main"
            }
            color="white"
          >
            <RemoveIcon
              fontSize="small"
              onClick={() => handleDecrementinputItems(rowIndex, columnIndex)}
            />
            <TextareaAutosize
              style={{
                textAlign: "center",
                border: "none",
                color: "white",
                backgroundColor:
                  appCode == AppCodeType.CPAISA ? "#FF9600" : "#14008D",
                fontWeight: 700,
                resize: "none",
              }}
              maxRows={1}
              minLength={1}
              maxLength={3}
              value={
                inputItems[rowIndex][columnIndex]?.quantity
                  ? inputItems[rowIndex][columnIndex].quantity.toString()
                  : ""
              }
              onChange={(e) => {
                handleChangeInputItemsQuantity(
                  parseInt(e.target.value),
                  rowIndex,
                  columnIndex
                );
              }}
            />

            <AddIcon
              fontSize="small"
              onClick={
                inputItems[rowIndex][columnIndex]?.quantity <
                item.total_quantity - item.raised_acknowledgement_quantity
                  ? () => handleIncrementinputItems(rowIndex, columnIndex)
                  : () => {}
              }
            />
          </Stack>
        )}
      {showAddItems &&
        deliveryRequestList[rowIndex].reward_status != Status.PENDING &&
        deliveryRequestList[rowIndex].reward_status != Status.PARTIAL && (
          <Stack
            direction={"row"}
            width={"30%"}
            height={40}
            alignItems="center"
            justifyContent="space-between"
            borderRadius={15}
            paddingX={2}
            bgcolor={
              appCode == AppCodeType.CPAISA ? "warning.main" : "secondary.main"
            }
            color="white"
          >
            <TextareaAutosize
              style={{
                textAlign: "center",
                border: "none",
                color: "white",
                backgroundColor:
                  appCode == AppCodeType.CPAISA ? "#FF9600" : "#FF9600",
                fontWeight: 700,
                resize: "none",
              }}
              maxRows={1}
              minLength={1}
              maxLength={3}
              value={item.raised_acknowledgement_quantity.toString()}
              readOnly
            />
          </Stack>
        )}
    </Stack>
  );
};

export default DeliveryItemCard;
