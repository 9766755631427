enum Status {
  PENDING = "pending",
  FULFILLED = "fulfilled",
  PARTIAL = "partial",
  REJECTED = "rejected",
  CANCELED = "canceled",
  ACKNOWLEDGEMENT_RAISED = "acknowledgement_raised",
  // PARTIALLY_FULFILLED = "partially_fulfilled",
  IN_ACTIVE = "in_active",
}

export default Status;
