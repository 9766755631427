import { StateCreator } from "zustand";

interface SelectedTabValueInterface {
  selectedTabValue: string;
  handleSelectedTab: Function;
}

const initialState = {
  selectedTabValue: "",
};

export const useSelectedTabValueSlice: StateCreator<
  SelectedTabValueInterface
> = (set) => {
  return {
    ...initialState,
    handleSelectedTab: (value: string) => {
      set(() => ({
        selectedTabValue: value,
      }));
    },
  };
};
