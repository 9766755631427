import HTTPStatusCode from "../../../common/enums/httpStatusCode";
import { DownloadLatestUploadedQPS } from "../../../domain/usages/stakeholder/download-latest-uploaded-qps";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpDownloadClient } from "../../protocols/http/http-download-client";

export class RemoteDownloadLatestUploadedQPS
  implements DownloadLatestUploadedQPS
{
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpDownloadClient
  ) {}

  async download(params: DownloadLatestUploadedQPS.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.download({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
        "Cache-Control": "no-cache",
      },
      authHeaders: true,
    });
    if (httpResponse.status === HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
