import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { DeliveryRequestList } from "../../../domain/models/stakeholder/delivery-request-list";
import { Constants } from "../../../common/constant";
import Status from "../../../common/enums/status";
import HistoryModal from "./HistoryModal";
import {
  BeneficiaryValidationMessage,
  QPSCardMessage,
  QPSMessageColor,
  QPSMessageIcon,
  statusBackgroundColor,
  statusColor,
  statusIcon,
  statusMessage,
} from "../../../common/utils";
import DeliveryItemCard from "./DeliveryItemCard";
import QPSDetailsModal from "./QPSDetailsModal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import ConfirmModal from "../modal/ConfirmModal";
import SuccessModal from "../modal/SuccessModal";
import ErrorModal from "../modal/ErrorModal";
import BeneficiaryValidationModal from "./BeneficiaryValidationModal";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY } from "../../../base";
import AppCodeType from "../../../common/enums/appCodeType";
import OTPModes from "../../../common/enums/OTPModes";

type Props = {
  deliveryRequest: DeliveryRequestList;
  rowIndex: number;
};

const CustomDot = ({ onClick, active }: any) => {
  return (
    <button
      style={{
        width: "10px",
        height: "10px",
        backgroundColor: active ? "#FF9600" : "#FDE6C5",
        border: "none",
        borderRadius: "50%",
        margin: "5px 5px 5px 5px",
        cursor: "pointer",
      }}
      onClick={() => onClick()}
    />
  );
};

const DeliveryRequestCard: React.FC<Props> = ({
  deliveryRequest,
  rowIndex,
}) => {
  const {
    fetchDeliveryRequestHistory,
    resendOTPRequest,
    resendRequestStatusMessage,
    onVerifyOTP,
    successOTPMessage,
    errorOTPMessage,
    handleLoading,
  } = useDeliveryRequestStore();

  const [openQPSDetailsModal, setOpenQPSDetailsModal] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openResendConfirmModal, setOpenResendConfirmModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openResendSuccessModal, setOpenResendSuccessModal] = useState(false);
  const [openResendErrorModal, setOpenResendErrorModal] = useState(false);
  const [openOTPModal, setOpenOTPModal] = useState(false);
  const [showResendButton, setShowResendButton] = useState<boolean>(false);
  const [OTPMode, setOTPMode] = useState<String>(OTPModes.SMS);

  const handleOpenQPSDetailsModal = () => setOpenQPSDetailsModal(true);
  const handleCloseQPSDetailsModal = () => {
    setOpenQPSDetailsModal(false);
    handleLoading(false);
  };
  const handleOpenResendConfirmModal = () => setOpenResendConfirmModal(true);
  const handleCloseResendConfirmModal = () => setOpenResendConfirmModal(false);
  const handleResendOpenSuccessModal = () => setOpenResendSuccessModal(true);
  const handleResendCloseSuccessModal = () => setOpenResendSuccessModal(false);
  const handleResendOpenErrorModal = () => setOpenResendErrorModal(true);
  const handleResendCloseErrorModal = () => setOpenResendErrorModal(false);
  const handleOpenSuccessModal = () => setOpenSuccessModal(true);
  const handleCloseSuccessModal = () => setOpenSuccessModal(false);
  const handleOpenErrorModal = () => setOpenErrorModal(true);
  const handleCloseErrorModal = () => setOpenErrorModal(false);
  const handleOpenOTPModal = () => setOpenOTPModal(true);
  const handleCloseOTPModal = () => setOpenOTPModal(false);

  const handleChangeOTPMode = (value: string) => {
    setOTPMode(value);
  };

  const handleShowResendButton = (value: boolean) => {
    setShowResendButton(value);
  };

  const handleOpenHistoryModal = (index: number) => {
    fetchDeliveryRequestHistory(index);
    setOpenHistoryModal(true);
  };
  const handleCloseHistoryModal = () => setOpenHistoryModal(false);

  const responsive = {
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const handleVerifyOTP = (captchaToken: string, otp: string) => {
    onVerifyOTP(
      handleOpenErrorModal,
      handleOpenSuccessModal,
      captchaToken,
      otp,
      rowIndex
    );
  };

  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));

  return (
    <>
      <Card color="info" variant="outlined">
        <CardContent>
          <Chip
            variant="filled"
            sx={{
              backgroundColor: statusBackgroundColor(
                deliveryRequest.reward_status
              ),
              borderRadius: 2,
              width: "100%",
            }}
            label={
              <Stack direction={"row"} spacing={1}>
                <img src={statusIcon(deliveryRequest.reward_status)} />
                <Typography
                  variant="caption"
                  fontWeight={700}
                  color={statusColor(deliveryRequest.reward_status)}
                >
                  {statusMessage(deliveryRequest.reward_status)}
                </Typography>
              </Stack>
            }
          />
          <Stack direction={"row"} justifyContent="space-between" marginY={2}>
            <Typography fontWeight={700} variant="subtitle2" color="info.main">
              {deliveryRequest.team_name}
            </Typography>
            <Typography fontWeight={700} variant="subtitle2">
              {`#${deliveryRequest.id}`}
            </Typography>
          </Stack>

          <Typography fontWeight={700} variant="subtitle2">
            {deliveryRequest.receiver.name
              ?.toLowerCase()
              .replace(Constants.FIRST_LETTER_REGEX, (letter) =>
                letter.toUpperCase()
              )}
          </Typography>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            marginBottom={2}
          >
            <Stack>
              <Typography variant="caption">
                Shop: {deliveryRequest.receiver.address.state},
                {deliveryRequest.receiver.address.pincode}{" "}
              </Typography>
              <Typography variant="caption">
                Contact:{" "}
                {"xxxxxx" +
                  deliveryRequest.receiver.mobile.slice(
                    deliveryRequest.receiver.mobile.length - 4
                  )}
              </Typography>
            </Stack>
            {deliveryRequest.delivery_request_items.length > 0 &&
              ![
                Status.FULFILLED,
                Status.REJECTED,
                Status.CANCELED,
                Status.ACKNOWLEDGEMENT_RAISED,
              ].includes(deliveryRequest.reward_status as Status) && (
                <Button
                  color={
                    appCode == AppCodeType.CPAISA ? "warning" : "secondary"
                  }
                  variant="contained"
                  onClick={handleOpenQPSDetailsModal}
                  sx={{ borderRadius: 20, paddingInline: 4 }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight={700}
                    textTransform="none"
                    color="white"
                  >
                    Handover
                  </Typography>
                </Button>
              )}
            {deliveryRequest.delivery_request_items.length > 0 &&
              [Status.FULFILLED, Status.REJECTED, Status.CANCELED].includes(
                deliveryRequest.reward_status as Status
              ) && (
                <Button
                  color={QPSMessageIcon(deliveryRequest.reward_status)}
                  variant="contained"
                  onClick={() => handleOpenHistoryModal(rowIndex)}
                  sx={{ borderRadius: 20, paddingInline: 2 }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight={700}
                    textTransform="none"
                    color="white"
                  >
                    View History
                  </Typography>
                </Button>
              )}
          </Stack>
          <Stack
            direction={"row"}
            marginBottom={2}
            justifyContent={"space-around"}
          >
            {deliveryRequest.delivery_request_items.length > 0 &&
              [Status.ACKNOWLEDGEMENT_RAISED].includes(
                deliveryRequest.reward_status as Status
              ) && (
                <Button
                  color={QPSMessageIcon(deliveryRequest.reward_status)}
                  variant="contained"
                  onClick={() => handleOpenHistoryModal(rowIndex)}
                  sx={{ borderRadius: 20, paddingInline: 2 }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight={700}
                    textTransform="none"
                    color="white"
                  >
                    View History
                  </Typography>
                </Button>
              )}

            {deliveryRequest.delivery_request_items.length > 0 &&
              deliveryRequest.reward_status == Status.ACKNOWLEDGEMENT_RAISED &&
              [
                Status.FULFILLED,
                Status.REJECTED,
                Status.CANCELED,
                Status.ACKNOWLEDGEMENT_RAISED,
              ].includes(deliveryRequest.reward_status as Status) && (
                <Button
                  color={
                    appCode == AppCodeType.CPAISA ? "warning" : "secondary"
                  }
                  variant="contained"
                  onClick={() => handleOpenOTPModal()}
                  sx={{ borderRadius: 20, paddingInline: 2 }}
                >
                  <Typography
                    variant="subtitle2"
                    fontWeight={600}
                    textTransform="none"
                    color="white"
                  >
                    Confirm Delivery
                  </Typography>
                </Button>
              )}
          </Stack>
          <Divider />

          <Stack>
            {deliveryRequest.delivery_request_items.length > 0 && (
              <Typography
                marginY={1}
                variant="caption"
                color={QPSMessageColor(deliveryRequest.reward_status)}
              >
                {QPSCardMessage(deliveryRequest.reward_status)}
              </Typography>
            )}
          </Stack>

          <Carousel
            showDots={true}
            arrows={false}
            responsive={responsive}
            customDot={<CustomDot />}
          >
            {deliveryRequest.delivery_request_items.length > 0 &&
              deliveryRequest.delivery_request_items.map(
                (item, index: number) => {
                  return (
                    <DeliveryItemCard
                      item={item}
                      columnIndex={index}
                      rowIndex={rowIndex}
                      showAddItems={false}
                    />
                  );
                }
              )}
          </Carousel>
          {deliveryRequest.delivery_request_items.length == 0 && (
            <Typography variant="caption" fontWeight={600}>
              No Items Found
            </Typography>
          )}
        </CardContent>
        <QPSDetailsModal
          openModal={openQPSDetailsModal}
          handleClose={handleCloseQPSDetailsModal}
          deliveryRequest={deliveryRequest}
          rowIndex={rowIndex}
        />
        <HistoryModal
          openModal={openHistoryModal}
          handleClose={handleCloseHistoryModal}
        />
      </Card>
      <SuccessModal
        openModal={openSuccessModal}
        handleClose={handleCloseSuccessModal}
        message={successOTPMessage}
      />
      <SuccessModal
        openModal={openResendSuccessModal}
        handleClose={handleResendCloseSuccessModal}
        message={resendRequestStatusMessage}
      />
      <BeneficiaryValidationModal
        handleShowResendButton={handleShowResendButton}
        showResendButton={showResendButton}
        openModal={openOTPModal}
        handleClose={handleCloseOTPModal}
        rowIndex={rowIndex}
        message={BeneficiaryValidationMessage(deliveryRequest.receiver.name)}
        successCallBack={handleVerifyOTP}
        resendSuccessCallBack={handleOpenResendConfirmModal}
      />
      <ErrorModal
        openModal={openErrorModal}
        handleClose={handleCloseErrorModal}
        message={errorOTPMessage}
      />
      <ErrorModal
        openModal={openResendErrorModal}
        handleClose={handleResendCloseErrorModal}
        message={resendRequestStatusMessage}
      />
      <ConfirmModal
        openModal={
          openResendConfirmModal &&
          deliveryRequest.reward_status === Status.ACKNOWLEDGEMENT_RAISED
        }
        handleClose={handleCloseResendConfirmModal}
        message={
          <Typography fontWeight={500} textAlign="center">
            {`Are you sure you want to resend OTP for ${deliveryRequest.receiver.name} ?`}
          </Typography>
        }
        showOTPMode={true}
        OTPMode={OTPMode}
        handleChangeOTPMode={handleChangeOTPMode}
        handleConfirm={() =>
          resendOTPRequest(
            rowIndex,
            handleCloseResendConfirmModal,
            handleResendOpenErrorModal,
            handleResendOpenSuccessModal,
            handleShowResendButton,
            handleResendCloseSuccessModal,
            OTPMode
          )
        }
      />
    </>
  );
};

export default DeliveryRequestCard;
