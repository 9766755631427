import React from "react";
import LoginForm from "../components/login/LoginForm";
import { Grid, Stack, Typography } from "@mui/material";
import IMAGES from "../../assets/images";

type Props = {};

const LoginPage = (props: Props) => {
  return (
    <Grid container height={"100vh"} width={"100%"} alignItems={"center"}>
      <Grid item xs={6}>
        <img src={IMAGES.LoginLogo} />
      </Grid>
      <Grid item xs={6}>
        <Stack
          justifyContent={"center"}
          height={"100%"}
          width={"90%"}
          spacing={5}
          alignItems={"center"}
        >
          <Stack direction={"column"} alignItems={"center"}>
            <Typography variant="h4">QPS Management Login</Typography>
          </Stack>

          <LoginForm />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
