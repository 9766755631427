import { Box, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AdminTabs from "../../../common/enums/adminTabs";
import { useAdminStore } from "../../../store/main/adminStore";

type Props = {};

const QPSTabs = (props: Props) => {
  const [value, setValue] = React.useState(AdminTabs.CAMPAIGN_CRITERIA);
  const { handleSelectedTab, campaignFilter } = useAdminStore();

  useEffect(() => {
    campaignFilter.appCode = "";
    handleSelectedTab(AdminTabs.CAMPAIGN_CRITERIA);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: AdminTabs) => {
    campaignFilter.appCode = "";
    handleSelectedTab(newValue);
    setValue(newValue);
  };
  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
      >
        {/* <Tab
          label={
            <Typography
              textTransform={"none"}
              variant={value === AdminTabs.DASHBOARD ? "h6" : "body1"}
            >
              Dashboard
            </Typography>
          }
          value={AdminTabs.DASHBOARD}
        /> */}
        <Tab
          label={
            <Typography
              variant={value === AdminTabs.CAMPAIGN_CRITERIA ? "h6" : "body1"}
              textTransform={"none"}
            >
              Campaign Criteria
            </Typography>
          }
          value={AdminTabs.CAMPAIGN_CRITERIA}
        />
      </Tabs>
    </Box>
  );
};

export default QPSTabs;
