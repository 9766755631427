import { Skeleton, Stack } from "@mui/material";

const AcknowledgementListSkeleton = () => {
  return (
    <>
            <Stack marginY={3}>
              <Stack direction={"row"} justifyContent="space-between">
                <Stack direction={"row"} spacing={1}>
                  <Skeleton animation="wave" variant="rectangular" width={90} height={25} />
                  <Skeleton animation="wave" variant="rectangular" width={70} height={25} />
                </Stack>

                <Stack>
                  <Skeleton animation="wave" variant="rectangular" width={70} height={20} />
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                mb={1}
              >
                <Skeleton animation="wave" variant="text" width={200} height={50} />
                <Skeleton animation="wave" variant="rectangular" width={50} height={20} />
              </Stack>

              <Skeleton animation="wave" variant="rectangular" height={2} />
            </Stack>
    </>
  );
};

export default AcknowledgementListSkeleton;
