import { Stack, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const data = [
  {
    name: "Jan",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Jan",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Feb",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Mar",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Apr",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "June",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "July",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Aug",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Sep",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Oct",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Nov",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
  {
    name: "Dec",
    pending: 40,
    accepted: 24,
    rejected: 24,
  },
];

type Props = {};

const MonthlyAcknowledgementReport = (props: Props) => {
  return (
    <Stack>
      <Typography marginLeft={1} variant="subtitle2">
        Monthly Acknowledgement Report
      </Typography>
      <BarChart
        width={700}
        height={200}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend align="right" verticalAlign="top" iconType="circle" />

        <Bar dataKey="pending" fill="#F39600" />
        <Bar dataKey="accepted" fill="#37CFA2" />
        <Bar dataKey="rejected" fill="#F7ACAC" />
      </BarChart>
    </Stack>
  );
};

export default MonthlyAcknowledgementReport;
