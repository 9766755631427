import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CustomIconButton from "./CustomIconButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import AdminTabs from "../../../common/enums/adminTabs";
import { pageRoutes } from "../../../routes";
import { useAdminStore } from "../../../store/main/adminStore";
import { useNavigate } from "react-router-dom";
import { Dayjs } from "dayjs";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import CustomerReportModal from "../uploadFile/CustomerReportModal";
import QPSAdminFilters from "./QPSAdminFilters";
import CampaignCriteriaCard from "./CampaignCriteriaCard";
import { ReportFilters } from "../../../domain/models/admin/report-filters";
import { FetchState } from "../../../common/enums/fetch-state";
import CampaignCriteriaCardLoading from "./loading/CampaignCriteriaCardLoading";
import FiltersLoading from "./loading/FiltersLoading";

type Props = {};

type FormFields = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  orgCode: string;
};

const CampaignCriteria = (props: Props) => {
  const { downloadCustomerReport } = useDeliveryRequestStore();
  const {
    handleSelectedTab,
    fetchCampaignCriteria,
    campaigns,
    campaignFilter,
    campaignLoading,
    appCodeLoading,
    fetchAppCodes,
  } = useAdminStore();
  const navigate = useNavigate();
  const [openCustomerReportFilter, setOpenCustomerReportFilter] =
    useState<boolean>(false);

  const handleQPSUploadButton = () => {
    handleSelectedTab(AdminTabs.UPLOAD_FILE);
    navigate(pageRoutes.admin);
  };

  const customerReportDownload = (data: FormFields) => {
    setOpenCustomerReportFilter(false);
    downloadCustomerReport(data);
  };

  const handleCloseCustomerReportFilterModal = () => {
    setOpenCustomerReportFilter(false);
  };

  useEffect(() => {
    fetchAppCodes();
    fetchCampaignCriteria();
  }, []);

  const handleFilters = (filters: ReportFilters) => {
    campaignFilter.appCode = filters.appCode;
    fetchCampaignCriteria();
  };

  return (
    <>
      <Stack spacing={5}>
        {appCodeLoading === FetchState.LOADING && <FiltersLoading />}
        {appCodeLoading === FetchState.SUCCESS && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <QPSAdminFilters
              type={AdminTabs.CAMPAIGN_CRITERIA}
              columns={13.5}
              width="50%"
              successCallback={handleFilters}
            />

            <Stack justifyContent={"end"} spacing={2} direction={"row"}>
              <CustomIconButton
                buttonTitle="Upload QPS"
                buttonColor="#FAF0E1"
                textAndIconColor="warning.main"
                onhoverButtonColor="warning.main"
                onhoverTextAndIconColor="white"
                onClick={handleQPSUploadButton}
                icon={<FileUploadRoundedIcon fontSize="small" />}
              />
              <CustomIconButton
                buttonTitle="Customer Report"
                buttonColor="#FAF0E1"
                textAndIconColor="warning.main"
                onhoverButtonColor="warning.main"
                onhoverTextAndIconColor="white"
                onClick={() => setOpenCustomerReportFilter(true)}
                icon={<FileDownloadIcon fontSize="small" />}
              />
              {/* <CustomIconButton
                buttonTitle="Campaign Details"
                buttonColor="#FAF0E1"
                textAndIconColor="warning.main"
                onhoverButtonColor="warning.main"
                onhoverTextAndIconColor="white"
                onClick={() => {}}
                icon={<FileDownloadIcon fontSize="small" />}
              /> */}
            </Stack>
          </Stack>
        )}

        {campaignLoading === FetchState.LOADING && (
          <Stack direction={"row"} spacing={5}>
            <CampaignCriteriaCardLoading />
            <CampaignCriteriaCardLoading />
            <CampaignCriteriaCardLoading />
          </Stack>
        )}
        {campaignLoading === FetchState.SUCCESS && (
          <Box overflow={"scroll"} paddingRight={3} width={"98%"}>
            <Stack direction={"row"} spacing={2}>
              {campaigns && campaigns.length > 0 ? (
                campaigns.map((campaign) => {
                  return <CampaignCriteriaCard campaignDetails={campaign} />;
                })
              ) : (
                <Typography variant="subtitle2" fontWeight={550}>
                  No Campaigns Available
                </Typography>
              )}
            </Stack>
          </Box>
        )}
      </Stack>
      <CustomerReportModal
        openModal={openCustomerReportFilter}
        handleClose={handleCloseCustomerReportFilterModal}
        SuccessCallback={customerReportDownload}
        message="Enter below details to download customer report"
      />
    </>
  );
};

export default CampaignCriteria;
