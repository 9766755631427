import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/system/Stack";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller, useForm } from "react-hook-form";
import { Dayjs } from "dayjs";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  p: 5,
};

type myProps = {
  openModal: boolean;
  handleClose: Function;
  message: React.ReactNode;
  SuccessCallback: Function;
};

type FormFields = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  orgCode: string;
};

const CustomerReportModal: React.FC<myProps> = ({
  openModal,
  handleClose,
  message,
  SuccessCallback,
}) => {
  const { handleSubmit, control, setValue, watch, register } =
    useForm<FormFields>({
      mode: "onChange",
    });
  const { clientsList, fetchClientsList } = useDeliveryRequestStore();

  useEffect(() => {
    handleClear();
  }, [openModal]);

  const onSubmit = (data: FormFields) => {
    SuccessCallback(data);
  };

  const handleClear = () => {
    setValue("startDate", null);
    setValue("endDate", null);
    setValue("orgCode", "");
  };
  useEffect(() => {
    fetchClientsList();
  }, []);

  return (
    <Modal open={openModal} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack alignItems={"end"} onClick={() => handleClose()}>
          <CloseIcon color="error" fontSize="small" />
        </Stack>
        <Typography variant="subtitle2" fontWeight={550}>
          {message}
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="center"
          spacing={2}
          my={5}
        >
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Start Date"
                    disableFuture
                    views={["year", "month"]}
                    value={value}
                    onChange={onChange}
                    minDate={"01/01/2022"}
                    inputFormat="MMMM YYYY"
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        error={error && true}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
          <Controller
            name="endDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="End Date"
                    disabled={watch("startDate") == null}
                    disableFuture
                    views={["year", "month"]}
                    value={value}
                    minDate={watch("startDate")}
                    onChange={onChange}
                    inputFormat="MMMM YYYY"
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        size="small"
                        error={error && true}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              </>
            )}
            rules={{
              required: { value: true, message: "Field Required" },
            }}
          />
        </Stack>
        <Controller
          name="orgCode"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl sx={{ width: "100%" }}>
              <InputLabel>Organisation</InputLabel>
              <Select
                disabled
                className="orgCode"
                fullWidth
                error={error && true}
                label="Application"
                onChange={onChange}
                value={value}
              >
                {clientsList.map((option) => (
                  <MenuItem key={option.id} value={option.short_code}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        <Stack direction={"row"} spacing={2} justifyContent={"center"} mt={2}>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleClear()}
          >
            <Typography color={"white"}>Clear</Typography>
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={watch("startDate") == null || watch("endDate") == null}
          >
            <Typography color={"white"}>Submit</Typography>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CustomerReportModal;
