import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

type Props = {
  cardDetails: {
    title: string;
    titleValue: string;
    backgroundColor?: string;
    textColor?: string;
    height?: string;
  }[];

  width?: number;
};

const MultiSummaryCard: React.FC<Props> = ({ cardDetails, width = 150 }) => {
  return (
    <Card sx={{ width: width, borderRadius: 2 }}>
      <Stack height={90}>
        {cardDetails.map((data) => {
          return (
            <Stack
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-around"}
              bgcolor={data.backgroundColor}
              height={data.height}
            >
              <Typography color={data.textColor} variant="subtitle2">
                {data.title}
              </Typography>
              <Typography color={data.textColor} variant="h5">
                {data.titleValue}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Card>
  );
};

export default MultiSummaryCard;
