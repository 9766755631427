import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
} from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import Endpoints from "../../../domain/endpoints";
import Swal from "sweetalert2";
import { RemoteDownloadLatestUploadedQPS } from "../../../data/usecases/stakeholder/remote-download-latest-uploaded-qps";
import { DownloadLatestUploadedQPS } from "../../../domain/usages/stakeholder/download-latest-uploaded-qps";

interface DownloadLatestUploadedQPSInterface {
  downloadLatestUploadedQPS: Function;
  handleDownloadLatestUploadedQPSStatus: Function;
}

const initialState = {};

export const useDownloadLatestUploadedQPSSlice: StateCreator<
  DownloadLatestUploadedQPSInterface
> = (set, get) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    handleDownloadLatestUploadedQPSStatus: (
      value: boolean,
      text?: string,
      status?: "success" | "error"
    ) => {
      console.log(value);
      if (value === true) {
        Swal.fire({
          title: "Please wait... File is getting downloaded",
          didOpen: () => {
            Swal.showLoading();
          },
        });
      } else {
        Swal.fire({
          icon: status,
          title: text,
          timer: 2000,
        });
      }
    },
    downloadLatestUploadedQPS: async () => {
      const token = storage.get(AUTH_TOKEN_KEY);
      const appCode = storage.get(APP_CODE_KEY);
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: atob(token),
        [APP_CODE]: atob(appCode),
      });
      const remoteDownloadLatestUploadedQPS: DownloadLatestUploadedQPS =
        new RemoteDownloadLatestUploadedQPS(
          `${INPUT_SERVICE_API_URL}${Endpoints.DOWNLOAD_LATEST_UPLOAD_QPS}`,
          axiosHttpClient
        );

      try {
        get().handleDownloadLatestUploadedQPSStatus(true);
        const response = await remoteDownloadLatestUploadedQPS.download();
        if (response) {
          const excelBlob = new Blob([response], { type: "application/xlsx" });
          const url = window.URL.createObjectURL(excelBlob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "latestUploadedQPS.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          get().handleDownloadLatestUploadedQPSStatus(
            false,
            "Downloaded Successfully!",
            "success"
          );
        } else {
          get().handleDownloadLatestUploadedQPSStatus(
            false,
            "Download Failed!",
            "error"
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
  };
};
