import {
  TableBody,
  TableCell,
  TableRow,
  Stack,
  Skeleton,
  Divider,
} from "@mui/material";
import React from "react";

type Props = {};

const HistoryLoading = (props: Props) => {
  return (
    <Stack>
      <Skeleton variant="rectangular" height={30} sx={{ borderRadius: 1 }} />
      <Stack
        direction={"row"}
        justifyContent="space-between"
        marginY={1}
        marginX={3}
      >
        <Skeleton
          variant="rectangular"
          height={30}
          width={100}
          sx={{ borderRadius: 1 }}
        />
        <Skeleton
          variant="rectangular"
          height={30}
          width={100}
          sx={{ borderRadius: 1 }}
        />
      </Stack>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        marginY={1}
        marginX={3}
      >
        <Skeleton
          variant="rectangular"
          height={30}
          width={100}
          sx={{ borderRadius: 1 }}
        />
        <Skeleton
          variant="rectangular"
          height={30}
          width={100}
          sx={{ borderRadius: 1 }}
        />
      </Stack>
      <Stack marginY={2}>
        <Divider />
      </Stack>
    </Stack>
  );
};

export default HistoryLoading;
