import { LoadingButton } from "@mui/lab";
import { Box, IconButton, Modal, Snackbar, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import OtpInput from "react18-input-otp";
import IMAGES from "../../../assets/images";
import ClockTimer from "./ClockTimer";
import { useDeliveryRequestStore } from "../../../store/main/deliveryRequestStore";
import CloseIcon from "@mui/icons-material/Close";
import AppCodeType from "../../../common/enums/appCodeType";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY, GOOGLE_RECAPTCHE_SITE_KEY } from "../../../base";
import useRecaptcha from "../hooks/reCaptcha";

type Props = {
  openModal: boolean;
  handleClose: Function;
  message: string;
  rowIndex: number;
  successCallBack: Function;
  showResendButton: boolean;
  resendSuccessCallBack: Function;
  handleShowResendButton: Function;
};

const InputStyle = {
  fontSize: 30,
  border: "1px solid orange",
  borderRadius: 10,
  padding: 4,
};
const CPAYInputStyle = {
  fontSize: 30,
  border: "1px solid #14008D",
  borderRadius: 10,
  padding: 10,
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
  border: "1px solid",
  height: "80%",
  overflowY: "auto",
};

const BeneficiaryValidationModal: React.FC<Props> = ({
  handleClose,
  openModal,
  message,
  rowIndex,
  successCallBack,
  resendSuccessCallBack,
  showResendButton,
  handleShowResendButton,
}) => {
  const [otp, setOtp] = useState("");

  const { deliveryRequestList, verifyOTPloading, resendRequestLoading } =
    useDeliveryRequestStore();

  const { token, executeRecaptcha } = useRecaptcha(
    GOOGLE_RECAPTCHE_SITE_KEY,
    "otp"
  );

  useEffect(() => {
    if (token != "" && token != null) {
      successCallBack(token, otp);
    }
  }, [token]);

  const handleVerifyOtp = () => {
    executeRecaptcha();
  };

  const handleChange = (enteredOtp: any) => {
    setOtp(enteredOtp);
  };

  const handleResendOTP = () => {
    resendSuccessCallBack();
  };

  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));

  return (
    <Modal open={openModal} onClose={() => handleClose()}>
      <Box sx={style}>
        <Box textAlign="end">
          <IconButton onClick={() => handleClose()}>
            <CloseIcon
              color="error"
              fontSize="small"
              onClick={() => handleClose()}
            />
          </IconButton>
        </Box>
        <Stack margin={3} marginBottom={0} justifyContent={"center"}>
          <Typography variant="caption" fontWeight={550} color={"success.main"}>
            {message}
          </Typography>
        </Stack>

        <Stack
          direction={"column"}
          alignItems={"center"}
          spacing={2}
          marginX={8}
          marginY={2}
        >
          <img src={IMAGES.Pharmacist} height={"85%"} width={"85%"} />

          <Typography variant="subtitle2" fontWeight={700}>
            Enter OTP sent to{" "}
            {"xxxxxx" +
              deliveryRequestList[rowIndex].receiver.mobile.slice(
                deliveryRequestList[rowIndex].receiver.mobile.length - 4
              )}
          </Typography>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={4}
            isInputNum
            inputStyle={
              appCode == AppCodeType.CPAISA ? InputStyle : CPAYInputStyle
            }
            separator={<Stack padding={1}> </Stack>}
          />
          <LoadingButton
            size="small"
            variant="contained"
            color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
            fullWidth
            loading={verifyOTPloading}
            loadingPosition="start"
            disabled={otp.length != 4}
            onClick={handleVerifyOtp}
          >
            <Typography color="white" textTransform={"none"}>
              Verify OTP
            </Typography>
          </LoadingButton>
          {showResendButton ? (
            <Stack>
              <LoadingButton
                size="small"
                variant="contained"
                color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
                loading={resendRequestLoading}
                loadingPosition="start"
                fullWidth
                onClick={handleResendOTP}
              >
                <Typography color="white" textTransform={"none"}>
                  Resend OTP
                </Typography>
              </LoadingButton>
            </Stack>
          ) : (
            <ClockTimer successCallback={handleShowResendButton} />
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default BeneficiaryValidationModal;
