import { create } from "zustand";
import { useFetchDeliveryRequestSlice } from "../slices/deliveryRequest/fetchDeliveryRequestSlice";
import { useDeliveryRequestItemSlice } from "../../presentation/slices/deliveryRequestItemSlice";
import { useDeliveryRequestSubmitSlice } from "../slices/deliveryRequest/deliveryRequestSubmitSlice";
import { useDeliveryRequestHistorySlice } from "../slices/deliveryRequest/deliveryRequestHistorySlice";
import { useResendOTPRequestSlice } from "../slices/deliveryRequest/resendOTPRequestSlice";
import { useDownloadQPSTemplateSlice } from "../slices/download/downloadQPSTemplate";
import { useDownloadCustomerReportSlice } from "../slices/download/downloadCustomerReport";
import { useDeliveryRequestBulkUpload } from "../slices/upload/deliveryRequestBulkUpload";
import { useDownloadLatestUploadedQPSSlice } from "../slices/download/downloadLatestUploadedQPS";
import { useFetchClientsSlice } from "../slices/download/fetchClients";
import { useVerifyCustomerOTPSlice } from "../slices/deliveryRequest/verifyCustomerOTPSlice";

interface DeliveryRequestInterface
  extends ReturnType<typeof useFetchDeliveryRequestSlice>,
    ReturnType<typeof useDeliveryRequestItemSlice>,
    ReturnType<typeof useDeliveryRequestSubmitSlice>,
    ReturnType<typeof useDeliveryRequestHistorySlice>,
    ReturnType<typeof useResendOTPRequestSlice>,
    ReturnType<typeof useDownloadQPSTemplateSlice>,
    ReturnType<typeof useDownloadCustomerReportSlice>,
    ReturnType<typeof useDeliveryRequestBulkUpload>,
    ReturnType<typeof useDownloadLatestUploadedQPSSlice>,
    ReturnType<typeof useFetchClientsSlice>,
    ReturnType<typeof useVerifyCustomerOTPSlice> {}

export const useDeliveryRequestStore = create<DeliveryRequestInterface>(
  (...data) => ({
    ...useFetchDeliveryRequestSlice(...data),
    ...useDeliveryRequestItemSlice(...data),
    ...useDeliveryRequestSubmitSlice(...data),
    ...useDeliveryRequestHistorySlice(...data),
    ...useResendOTPRequestSlice(...data),
    ...useDownloadQPSTemplateSlice(...data),
    ...useDownloadCustomerReportSlice(...data),
    ...useDeliveryRequestBulkUpload(...data),
    ...useDownloadLatestUploadedQPSSlice(...data),
    ...useFetchClientsSlice(...data),
    ...useVerifyCustomerOTPSlice(...data),
  })
);
