const Endpoints = {
  //StakeHolder api
  FETCH_DELIVERY_REQUEST: "/v1/delivery-requests",
  SUBMIT_ACKNOWLEDGEMENT: "/v1/delivery-requests/acknowledgements",
  DELIVERY_REQUEST_HISTORY: "/v1/delivery-requests/history",
  RESEND_REQUEST: "/v1/delivery-requests/acknowledgements/:requestId/resend",
  RESEND_OTP: "/v1/otps/resend",
  VERIFY_OTP: "/v1/otps/verify",

  //admin
  DOWNLOAD_QPS_TEMPLATE: "/v1/download/qps-template",
  DOWNLOAD_QPS_CUSTOMER_REPORT: "/v1/download/customer-report",
  DOWNLOAD_LATEST_UPLOAD_QPS: "/v1/download/last-uploaded-qps",
  DELIVERY_REQUESTS_BULK_UPLOAD: "/v1/upload/qps",
  FETCH_CLIENTS: "/v4/clients",
  FETCH_CAMPAIGN_CRITERIA: "/v1/campaigns",
  FETCH_APP_CODES: "/v1/projects",

  //Beneficiary API
  FETCH_ACKNOWLEDGEMENT: "/v1/delivery-requests/:uuid/acknowledgements",
};

export default Endpoints;
