import { Box, Stack, Typography } from "@mui/material";
import IMAGES from "../../assets/images";

type Props = {
  clientSideError?: boolean;
};

const ErrorPage: React.FC<Props> = ({ clientSideError = false }) => {
  return (
    <>
      {clientSideError && (
        <Box
          display="grid"
          textAlign={"center"}
          alignItems="center"
          height={"90vh"}
        >
          <img src={IMAGES.Error} />
          <Typography variant="h6">Something went wrong</Typography>
        </Box>
      )}
    </>
  );
};

export default ErrorPage;
