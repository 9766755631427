import { StateCreator } from "zustand";
import { SelectedRequestedItem } from "../../domain/models/stakeholder/selected-request-item";
import { RequestItem } from "../../domain/models/stakeholder/request-item";
import { useDeliveryRequestStore } from "../../store/main/deliveryRequestStore";

interface FetchDeliveryRequestInterface {
  handleIncrementinputItems: Function;
  handleDecrementinputItems: Function;
  handleInputItems: Function;
  inputItems: SelectedRequestedItem[][];
  handleChangeInputItemsQuantity: Function;
  showFilter: boolean;
  handleShowFilter: Function;
  handleAppliedFilters: Function;
  checkQuantityValdation: Function;
  quantityError: boolean;
}

const initialState = {
  inputItems: [],
  showFilter: false,
  quantityError: false,
};

export const useDeliveryRequestItemSlice: StateCreator<
  FetchDeliveryRequestInterface
> = (set, get) => {
  return {
    ...initialState,
    handleIncrementinputItems: (rowIndex: number, columnIndex: number) => {
      let copy = [...get().inputItems];
      copy[rowIndex][columnIndex].quantity =
        get().inputItems[rowIndex][columnIndex].quantity + 1;
      set(() => ({
        inputItems: copy,
      }));
    },
    handleDecrementinputItems: (rowIndex: number, columnIndex: number) => {
      let copy = [...get().inputItems];
      if (copy[rowIndex][columnIndex].quantity > 0) {
        copy[rowIndex][columnIndex].quantity =
          get().inputItems[rowIndex][columnIndex].quantity - 1;
        set(() => ({
          inputItems: copy,
        }));
      }
    },
    handleInputItems: (
      value: RequestItem,
      rowIndex: number,
      columnIndex: number
    ) => {
      let copy = [...get().inputItems];
      copy[rowIndex][columnIndex] = {} as SelectedRequestedItem;
      copy[rowIndex][columnIndex].delivery_request_item_id =
        value.delivery_request_item_id;
      copy[rowIndex][columnIndex].quantity = 0;

      set(() => ({
        inputItems: copy,
      }));
    },
    checkQuantityValdation: (
      value: number,
      rowIndex: number,
      columnIndex: number
    ) => {
      if (
        value >
        useDeliveryRequestStore.getState().deliveryRequestList[rowIndex]
          .delivery_request_items[columnIndex].total_quantity -
          useDeliveryRequestStore.getState().deliveryRequestList[rowIndex]
            .delivery_request_items[columnIndex].raised_acknowledgement_quantity
      ) {
        return false;
      }
      return true;
    },
    handleChangeInputItemsQuantity: (
      value: number,
      rowIndex: number,
      columnIndex: number
    ) => {
      let copy = [...get().inputItems];
      if (
        get().checkQuantityValdation(value ? value : 0, rowIndex, columnIndex)
      ) {
        copy[rowIndex][columnIndex].quantity = value ? value : 0;
        set(() => ({
          inputItems: copy,
        }));
      } else {
        set(() => ({
          quantityError: true,
        }));
      }
    },
    handleAppliedFilters: () => {
        useDeliveryRequestStore
        .getState()
        .fetchDeliveryRequestList(useDeliveryRequestStore.getState().filter.q, 1, false);
    },
    handleShowFilter: (value: boolean) => {
      set(() => ({
        showFilter: value,
      }));
    },
  };
};
