import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
  UUID_KEY,
} from "../../../base";
import { FetchState } from "../../../common/enums/fetch-state";
import { RemoteFetchAcknowledgement } from "../../../data/usecases/beneficiary/remote-fetch-acknowledgement";
import Endpoints from "../../../domain/endpoints";
import { AcknowledgementList } from "../../../domain/models/beneficiary/acknowledgement-list";
import { AcknowledgementFilters } from "../../../domain/models/beneficiary/acknowledgementFilter";
import { Result } from "../../../domain/models/result";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { RemoteFetchCampaignCriteria } from "../../../data/usecases/admin/remote-fetch-campaign-criteria";
import { FetchCampaignCriteria } from "../../../domain/usages/admin/fetch-campaign-criteria";
import { CampaignCriteria } from "../../../domain/models/admin/campaignCriteria";

interface CampaignCriteriaInterface {
  campaigns: CampaignCriteria[];
  campaignLoading: FetchState;
  fetchCampaignCriteria: Function;
  campaignFilter: {
    appCode?: string;
  };
}

const storage = LocalJsonStorage.getInstance();
const axiosHttpClient = AxiosHttpClient.getInstance();

const initialState = {
  campaignLoading: FetchState.DEFAULT,
  campaigns: [],
  campaignFilter: {
    appCode: "",
  },
};

export const useCampaignCriteriaListSlice: StateCreator<
  CampaignCriteriaInterface
> = (set, get) => {
  {
    return {
      ...initialState,
      fetchCampaignCriteria: async () => {
        const token = storage.get(AUTH_TOKEN_KEY);
        const appCode = storage.get(APP_CODE_KEY);

        axiosHttpClient.setAuthHeaders({
          [AUTH_HEADER]: atob(token),
          [APP_CODE]: atob(appCode),
        });
        const remoteFetchCampaignCriteria: FetchCampaignCriteria =
          new RemoteFetchCampaignCriteria(
            `${INPUT_SERVICE_API_URL}${Endpoints.FETCH_CAMPAIGN_CRITERIA}`,
            axiosHttpClient
          );

        let payload = {
          app_code: get().campaignFilter.appCode,
        };
        set(() => ({
          campaignLoading: FetchState.LOADING,
        }));
        let result = await remoteFetchCampaignCriteria.fetch(payload);
        if (result.success) {
          set(() => ({
            campaigns: result.campaigns,
            campaignLoading: FetchState.SUCCESS,
          }));
        }
      },
    };
  }
};
