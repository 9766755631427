import Swal from "sweetalert2";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpGetClient } from "../../protocols/http/http-get-client";
import { FetchAppCodes } from "../../../domain/usages/admin/fetch-app-codes";

export class RemoteFetchAppCodes implements FetchAppCodes {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpGetClient
  ) {}

  async fetch(params?: FetchAppCodes.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.get({
      url: this.url,
      query: params,
      headers: {
        "ngrok-skip-browser-warning": "1",
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
      },
      authHeaders: false,
    });
    if (httpResponse.status === 200) {
      return httpResponse.data;
    }
  }
}
