import HTTPStatusCode from "../../../common/enums/httpStatusCode";
import { DownloadQPSTemplate } from "../../../domain/usages/stakeholder/download-qps-template";
import { HttpConstants } from "../../protocols/http/http-constants";
import { HttpDownloadClient } from "../../protocols/http/http-download-client";

export class RemoteDownloadQPSTemplate implements DownloadQPSTemplate {
  constructor(
    private readonly url: string,
    private readonly httpGetClient: HttpDownloadClient
  ) {}

  async download(params?: DownloadQPSTemplate.Params): Promise<any> {
    const httpResponse = await this.httpGetClient.download({
      url: this.url,
      query: params,
      headers: {
        [HttpConstants.CONTENT_TYPE]: HttpConstants.APPLICATION_JSON,
        [HttpConstants.ACCEPT]: HttpConstants.APPLICATION_JSON,
        "Cache-Control": "no-cache",
      },
      authHeaders: true,
    });
    if (httpResponse.status === HTTPStatusCode.OK) {
      return httpResponse.data;
    }
  }
}
