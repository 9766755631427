import Header from "../components/admin/Header";
import { Stack } from "@mui/material";
import UploadQPSFile from "../components/admin/UploadQPSFile";
import { useAdminStore } from "../../store/main/adminStore";
import AdminTabs from "../../common/enums/adminTabs";
import CampaignCriteria from "../components/admin/CampaignCriteria";
import Dashboard from "../components/admin/Dashboard";

type Props = {};

const AdminPage = (props: Props) => {
  const { selectedTabValue } = useAdminStore();
  return (
    <Stack spacing={2} marginX={2}>
      <Header />
      {selectedTabValue === AdminTabs.UPLOAD_FILE && <UploadQPSFile />}
      {selectedTabValue === AdminTabs.DASHBOARD && <Dashboard />}
      {selectedTabValue === AdminTabs.CAMPAIGN_CRITERIA && <CampaignCriteria />}
    </Stack>
  );
};

export default AdminPage;
