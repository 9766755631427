import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useState } from "react";
import Header from "../../Header";
import AcknowledgementStatusTabs from "./AcknowledgementStatusTabs";
import FilterModal from "./FilterModal";
import AcknowledgementList from "./AcknowledgementList";
import { useBeneficiaryStore } from "../../../../store/main/beneficiaryStore";

const ViewAcknowledgement = () => {
  const { showFilter } = useBeneficiaryStore();

  const [isModal, setIsModal] = useState<boolean>(false);

  const handleModal = () => {
    setIsModal(!isModal);
  };

  const handleClose = () => {
    setIsModal(false);
  };
  return (
    <Stack marginX={3}>
      <Box
        position={"fixed"}
        zIndex={1}
        width="100%"
        sx={{ backgroundColor: "white" }}
        gap={1}
      >
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          marginTop={3}
          width="90%"
        >
          <Header title={"Acknowledgement"} />
          {showFilter && (
            <Stack direction={"row"} onClick={handleModal} gap={1}>
              <Typography
                color="warning.light"
                fontWeight={700}
                variant="subtitle2"
              >
                Filter
              </Typography>
              <FilterListIcon fontSize="small" color="warning" />
            </Stack>
          )}
        </Stack>

        <AcknowledgementStatusTabs />
      </Box>

      <Box marginTop={13}>
        <AcknowledgementList />
      </Box>

      <FilterModal handleClose={handleClose} openModal={isModal} />
    </Stack>
  );
};

export default ViewAcknowledgement;
