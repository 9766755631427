import { Card, CardContent, CardHeader, Skeleton, Stack } from "@mui/material";

type Props = {};

const CampaignCriteriaCardLoading = (props: Props) => {
  return (
    <Card
      sx={{ bgcolor: "#FAF8F9", borderRadius: 5, width: 400, height: "70vh" }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Skeleton variant="rectangular" height={20} width={"90%"} />
            <Skeleton variant="rectangular" height={20} width={"90%"} />
          </Stack>

          <Card variant="outlined">
            <CardHeader
              title={
                <Skeleton variant="rectangular" height={40} width={"90%"} />
              }
              subheader={
                <Skeleton
                  variant="rectangular"
                  height={40}
                  width={"90%"}
                  sx={{ marginTop: 1 }}
                />
              }
              avatar={<Skeleton variant="circular" height={56} width={56} />}
            />
          </Card>
          <Card variant="outlined">
            <CardHeader
              title={
                <Skeleton variant="rectangular" height={40} width={"90%"} />
              }
              subheader={
                <Skeleton
                  variant="rectangular"
                  height={40}
                  width={"90%"}
                  sx={{ marginTop: 1 }}
                />
              }
              avatar={<Skeleton variant="circular" height={56} width={56} />}
            />
          </Card>
          <Card variant="outlined">
            <CardHeader
              title={
                <Skeleton variant="rectangular" height={40} width={"90%"} />
              }
              subheader={
                <Skeleton
                  variant="rectangular"
                  height={40}
                  width={"90%"}
                  sx={{ marginTop: 1 }}
                />
              }
              avatar={<Skeleton variant="circular" height={56} width={56} />}
            />
          </Card>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CampaignCriteriaCardLoading;
