import React from "react";
import QPSAdminFilters from "./QPSAdminFilters";
import AdminPage from "../../pages/AdminPage";
import AdminTabs from "../../../common/enums/adminTabs";
import SummaryCard from "./SummaryCard";
import { Card, Container, Stack } from "@mui/material";
import MultiSummaryCard from "./MultiSummaryCard";
import CampaignList from "./CampaignList";
import AppWiseQPSReport from "./charts/AppWiseQPSReport";
import MonthlyAcknowledgementReport from "./charts/MonthlyAcknowledgementReport";

type Props = {};

const Dashboard = (props: Props) => {
  return (
    <Stack spacing={2}>
      <QPSAdminFilters
        type={AdminTabs.DASHBOARD}
        columns={18.5}
        width="100%"
        successCallback={() => {}}
      />
      <Stack direction={"row"} spacing={10} alignItems={"center"}>
        <SummaryCard
          title="QPS ack by rep"
          titleValue="104"
          cardColor="#DDBAA7"
          textColor="white"
        />
        <SummaryCard
          title="QPS Uploaded"
          titleValue="104"
          cardColor="#588B8B"
          textColor="white"
        />
        <SummaryCard
          title="QPS ack by CP's"
          titleValue="104"
          cardColor="#9D6381"
          textColor="white"
        />
        <MultiSummaryCard
          cardDetails={[
            {
              title: "Ack rejected",
              titleValue: "104",
              backgroundColor: "#6e4e72",
              textColor: "white",
              height: "50%",
            },
            {
              title: "Ack approved",
              titleValue: "104",
              backgroundColor: "#4A224F",
              textColor: "white",
              height: "50%",
            },
          ]}
          width={200}
        />
        <SummaryCard
          title="QPS ack pending"
          titleValue="104"
          cardColor="#C19875"
          textColor="white"
        />
      </Stack>
      <Stack spacing={2} direction={"row"}>
        <Card sx={{ height: "30%" }}>
          <MonthlyAcknowledgementReport />
        </Card>
        <Card sx={{ height: "30%" }}>
          <AppWiseQPSReport />
        </Card>
      </Stack>

      <CampaignList />
    </Stack>
  );
};

export default Dashboard;
