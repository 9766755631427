import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/system/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { APP_CODE_KEY } from "../../../base";
import AppCodeType from "../../../common/enums/appCodeType";
import OTPModeSelect from "../OTPModeSelect";

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 3,
};

type myProps = {
  openModal: boolean;
  handleClose: Function;
  message: React.ReactNode;
  handleConfirm: Function;
  showOTPMode?: boolean;
  OTPMode?: String;
  handleChangeOTPMode?: Function;
};

const ConfirmModal: React.FC<myProps> = ({
  openModal,
  handleClose,
  message,
  handleConfirm,
  showOTPMode = false,
  OTPMode = "",
  handleChangeOTPMode = () => {},
}) => {
  const storage = LocalJsonStorage.getInstance();
  const appCode = atob(storage.get(APP_CODE_KEY));

  const handleOTPModeChange = (value: string) => {
    handleChangeOTPMode(value);
  };

  return (
    <Modal open={openModal} onClose={() => handleClose()}>
      <Box sx={style}>
        <Stack spacing={2}>
          <Stack
            direction="row"
            alignItems={"center"}
            pt={1}
            pr={1}
            justifyContent="end"
          >
            <IconButton onClick={() => handleClose()}>
              <CloseIcon color="error" fontSize="small" />
            </IconButton>
          </Stack>
          <Stack p={3} pt={0} spacing={2}>
            {message}
            {showOTPMode && (
              <Stack alignItems={"center"}>
                <OTPModeSelect
                  OTPMode={OTPMode}
                  handleOTPModeChange={handleOTPModeChange}
                  appCode={appCode}
                  message="Choose the otp mode for your customer"
                />
              </Stack>
            )}

            <LoadingButton
              variant="contained"
              color={appCode == AppCodeType.CPAISA ? "warning" : "secondary"}
              onClick={() => handleConfirm(OTPMode)}
            >
              <Typography variant="body1" textTransform={"none"} color="white">
                Confirm
              </Typography>
            </LoadingButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
