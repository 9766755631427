import { StateCreator } from "zustand";
import { LoginFormFields } from "../../../domain/models/auth/loginFormFields";
import LoginDetails from "../../../common/enums/loginDetails";
import Swal from "sweetalert2";

interface LoginInterface {
  loading: boolean;
  loggedIn: boolean;
  verifyLoginDetails: Function;
}

const initialState = {
  loggedIn: false,
  loading: false,
};

export const useLoginSlice: StateCreator<LoginInterface> = (set) => {
  return {
    ...initialState,
    verifyLoginDetails: (data: LoginFormFields, handleNavigation: Function) => {
      set(() => ({
        loggedIn: false,
        loading: true,
      }));
      if (
        (data.userName == LoginDetails.USERNAME1 ||
          data.userName == LoginDetails.USERNAME2) &&
        data.password == LoginDetails.PASSWORD
      ) {
        set(() => ({
          loggedIn: true,
          loading: false,
        }));
        handleNavigation(true);
      } else {
        set(() => ({
          loading: false,
        }));
        Swal.fire("Invalid Login", "", "error");
      }
    },
  };
};
