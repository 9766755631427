import React from "react";
import StakeholderScreen from "./presentation/pages/StakeholderScreen";
import BeneficiaryScreen from "./presentation/pages/BeneficiaryScreen";
import AuthenticationPage from "./presentation/pages/AuthenticationPage";
import LoginPage from "./presentation/pages/LoginPage";
import AdminPage from "./presentation/pages/AdminPage";
import Dashboard from "./presentation/components/admin/Dashboard";
import CampaignCriteria from "./presentation/components/admin/CampaignCriteria";

export const pageRoutes = {
  authenticate: "/auth/:authToken",
  requestDeliveryList: "/request-delivery-list",
  acknowledgementList: "/acknowledgement-list",
  login: "/login",
  admin: "/admin",
  dashboard: "/dashboard",
  campaignCriteriaScreen: "/campaign-criteria",
};

export interface AppRoute {
  path: string;
  name: string;
  component: React.FC;
  children?: AppRoute[];
}

let indexRoutes: AppRoute[] = [
  {
    path: pageRoutes.login,
    name: "Login Page",
    component: LoginPage,
  },
  {
    path: pageRoutes.authenticate,
    name: "Authenticate Page",
    component: AuthenticationPage,
  },
  {
    path: pageRoutes.requestDeliveryList,
    name: "Customer List",
    component: StakeholderScreen,
  } as AppRoute,
  {
    path: pageRoutes.acknowledgementList,
    name: "Acknowledgement List",
    component: BeneficiaryScreen,
  } as AppRoute,
  {
    path: pageRoutes.admin,
    name: "Upload File",
    component: AdminPage,
  } as AppRoute,
  {
    path: pageRoutes.dashboard,
    name: "dashboard screen",
    component: Dashboard,
  } as AppRoute,
  {
    path: pageRoutes.campaignCriteriaScreen,
    name: "campaign Criteria Screen",
    component: CampaignCriteria,
  } as AppRoute,
];

export default indexRoutes;
