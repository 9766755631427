import { StateCreator } from "zustand";
import {
  APP_CODE,
  APP_CODE_KEY,
  AUTH_HEADER,
  AUTH_TOKEN_KEY,
  INPUT_SERVICE_API_URL,
} from "../../../base";
import { LocalJsonStorage } from "../../../infra/http/local-json-storage";
import { AxiosHttpClient } from "../../../infra/http/axios-http-client";
import { Result } from "../../../domain/models/result";
import Endpoints from "../../../domain/endpoints";
import { useDeliveryRequestStore } from "../../main/deliveryRequestStore";
import { RemoteResendOTPRequest } from "../../../data/usecases/stakeholder/remote-resend-otp-request";
import { ResendOTPRequest } from "../../../domain/usages/stakeholder/resend-otp-request";

interface ResendOTPRequestInterface {
  resendRequestLoading: boolean;
  resendRequestStatusMessage: string;
  resendOTPRequest: Function;
}

const initialState = {
  resendRequestLoading: false,
  resendRequestStatusMessage: "",
};

export const useResendOTPRequestSlice: StateCreator<
  ResendOTPRequestInterface
> = (set) => {
  const storage = LocalJsonStorage.getInstance();
  const axiosHttpClient = AxiosHttpClient.getInstance();

  return {
    ...initialState,
    resendOTPRequest: async (
      index: number,
      handleCloseConfirmModal: Function,
      handleResendOpenErrorModal: Function,
      handleResendOpenSuccessModal: Function,
      handleShowResendButton: Function,
      handleResendCloseSuccessModal: Function,
      OTPMode: string
    ) => {
      const token = storage.get(AUTH_TOKEN_KEY);
      const appCode = storage.get(APP_CODE_KEY);
      axiosHttpClient.setAuthHeaders({
        [AUTH_HEADER]: atob(token),
        [APP_CODE]: atob(appCode),
      });
      const remoteResendOTPRequest: ResendOTPRequest =
        new RemoteResendOTPRequest(
          `${INPUT_SERVICE_API_URL}${Endpoints.RESEND_OTP}`,
          axiosHttpClient
        );

      let payload = {
        delivery_request_id: useDeliveryRequestStore
          .getState()
          .deliveryRequestList[index].id.toString(),
          mode: OTPMode
      };
      set(() => ({
        resendRequestLoading: true,
      }));
      handleCloseConfirmModal();
      let result: Result = await remoteResendOTPRequest.resend(payload);
      if (result.success) {
        set(() => ({
          resendRequestLoading: false,
          resendRequestStatusMessage: result.otp,
        }));

        handleResendOpenSuccessModal();
        setTimeout(
          () =>
          handleResendCloseSuccessModal(),
          3000
        );
       
        handleShowResendButton(false)
      } else if (result.errors.message) {
        set(() => ({
          resendRequestLoading: false,
        }));
        set(() => ({
          resendRequestStatusMessage: result.errors.message,
        }));
        handleResendOpenErrorModal();
      }  else {
        set(() => ({
          resendRequestLoading: false,
        }));
        if (result && result.errors) {
          set(() => ({
            resendRequestStatusMessage: result.errors.message,
          }));
        } else {
          set(() => ({
            resendRequestStatusMessage: result.message,
          }));
        }
        handleResendOpenErrorModal();
      }
    },
  };
};
