import { Chip, Divider, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Constants } from "../../../common/constant";
import {
  requestStatus,
  statusBackgroundColor,
  statusColor,
} from "../../../common/utils";
import { DeliveryRequestHistoryList } from "../../../domain/models/stakeholder/delivery-request-history-list";

type Props = {
  request: DeliveryRequestHistoryList;
};

const HistoryCard: React.FC<Props> = ({ request }) => {
  return (
    <>
      <Chip
        variant="filled"
        sx={{
          backgroundColor: statusBackgroundColor(request.status),
          borderRadius: 2,
          width: "100%",
        }}
        label={
          <Stack direction={"row"} spacing={1}>
            <Typography
              variant="caption"
              fontWeight={700}
              color={statusColor(request.status)}
            >
              {` ${requestStatus(request.status)} on ${
                request.created_date &&
                moment(request.created_date).format(Constants.DATE_FORMAT)
              }`}
            </Typography>
          </Stack>
        }
      />
      {request.delivery_request_acknowledgement_item_logs.length > 0 &&
        request.delivery_request_acknowledgement_item_logs.map((item) => {
          return (
            <Stack
              direction={"row"}
              justifyContent="space-between"
              marginY={1}
              marginX={3}
            >
              <Typography variant="caption">
                {item.campaign_input.name}
              </Typography>
              <Typography variant="caption">{item.quantity}</Typography>
            </Stack>
          );
        })}
      <Stack marginY={2}>
        <Divider color={"#E7E7E7"} />
      </Stack>
    </>
  );
};

export default HistoryCard;
